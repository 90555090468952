import { Rate, Button, Spin, Modal, Input, notification, Badge } from "antd";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faCalendar,
  faSearch,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { UPDATEDGET, UPDATEDPOST } from "../../services/common.api";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import DoctorProfile from "../../pages/doctorProfile/index";
import { selectedDoctor } from "../../redux/actions/userActions";
import {
  selectedDoctorProfile,
  bookAppointmentDoctor,
} from "../../redux/actions/userActions";
import style from "./style.module.css";
import React, { useEffect, useState } from "react";
import { setStartTimeBasedOnCallBackRequest } from "../../utils/common";
import moment from "moment";
import { isMobile, isTablet, isBrowser } from 'react-device-detect';

import { IMG01 } from "./img";

export const DoctorAppointmentBookNew = () => {
  const { Search } = Input;
  const dispatch = useDispatch();
  const history = useHistory();
  const [doctorList, setdoctorList] = useState([]);
  const [doctorListDummy, setdoctorListDummy] = useState([]);
  const [familyDocList, setFamilyDocList] = useState([] as any);
  const [familyDocListDummy, setFamilyDocListDummy] = useState([] as any);
  const [fDoc, setFDoc] = useState([] as any);
  const [familyDoc, setFamilyDoc] = useState({} as any);
  const [clinicId, setClientId] = useState(null);
  const [loader, setLoader] = useState(false);
  const [companyEmpId, setCompanyEmpId] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const paymentProvider = useSelector(
    (state: any) => state.curentAppointment.paymentProvider
  );
  const userData = useSelector((state: any) => state.userReducer.user);
  const problemId = useSelector(
    (state: any) => state.curentAppointment.problem
  );
  const whichVersion = useSelector(
    (state: any) => state.curentAppointment.whichVersion
  );
  const appointmentTypeNew = useSelector((state: any) => state);
  const brandInformation: any = useSelector(
    (state: any) => state.brandReducer.brandInfo
  );
  console.log("brandInformation", brandInformation);
  const userAgent = navigator.userAgent;

  const showModal = (item, docId) => {
    localStorage.setItem("DOC_ID", docId);
    console.log("showModal Profile");
    setIsModalVisible(true);
    dispatch(selectedDoctorProfile(item));
    console.log(isModalVisible, "isModalVisible");
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const logEvents = async (data) => {
    console.log(data, "LOG EVENTTT")
    try {
      let result = await UPDATEDPOST(`log`, data)
    } catch(e) {
      console.log(e, "ERRORRR")
    }
  }

  useEffect(() => {
    if (whichVersion == "with_problem") {
      getDoctorsFromProblem();
    } else {
      getFamilyDoctor();
      setClientId(userData?.CompanyEmployee[0]?.CompanyID);
      getDoctors();
    }
  }, []);

  useEffect(() => {
    if (whichVersion != "with_problem") {
      // getDoctors();
    }
    onSearch("");
  }, [clinicId]);

  const getDoctorsFromProblem = async () => {
    console.log("ok", problemId);
    // try {
    //   setLoader(true);
    //   const doctorData = await GET(`provider/problem/` + problemId);
    //   console.log(doctorData, "Doctordataaaaaaaa");

    //   if (doctorData.data.statusCode == 200) {
    //     setLoader(true);
    //     setdoctorList(doctorData.data.body);
    //     setdoctorListDummy(doctorData.data.body);
    //   }
    //   setLoader(false);
    // } catch (error) {
    //   console.log(error);
    //   setLoader(false);
    // }
  };

  const getDoctors = async () => {

    console.log("ok");
    try {
      setLoader(true);
      const doctorData = await UPDATEDGET(`employee/doctors?type=`+paymentProvider);

      let rawLogs = {
        "userIdentity": {
          "loggedinuser": `${userData?.Email}`,
          "role":`${userData?.role}`,
          "createdate":new Date().toISOString()
        },
        "action": "ACCESS",
        "source": "Doctor Appointment Book New Component",
        "sourceIPAddress": "3.99.184.26",
        "userAgent": userAgent,
        "ipAddress": localStorage.getItem('ipAddress'),
        "apiendpoint": `employee/doctors?type=`+paymentProvider,
        "requestParameters": {},
        "responsestatus": doctorData?.status
      }

      let logBody = {
        // message: `User - ${userData?.sub} - Fetch Doctors - Doctor Appointment Book New Component`,
        message: rawLogs,
        logGroupName: "ONRx-Patient-Portal",
        logStreamName: `User - ${userData?.sub} - Doctor Appointment Book New Component`,
      }
  
      logEvents(logBody);

      console.log(doctorData, "All Doctor List");
      if(brandInformation?.is_brand && brandInformation?.user_id == userData?.id){
        const filteredData = doctorData?.data?.filter(item => item.CompanyID === brandInformation?.clinic_id);
        setdoctorList(filteredData);
        setdoctorListDummy(filteredData);
      }else{
        setdoctorList(doctorData.data);
        setdoctorListDummy(doctorData.data);

      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const getFamilyDoctor = async () => {

    try {
      setLoader(true);
      const familyDoctor = await UPDATEDGET(`patient/familydoc/${userData.id}`);

      let rawLogs = {
        "userIdentity": {
          "loggedinuser": `${userData?.Email}`,
          "role":`${userData?.role}`,
          "createdate":new Date().toISOString()
        },
        "action": "ACCESS",
        "source": "Doctor Appointment Book New Component",
        "sourceIPAddress": "3.99.184.26",
        "userAgent": userAgent,
        "ipAddress": localStorage.getItem('ipAddress'),
        "apiendpoint": `patient/familydoc/${userData.id}`,
        "requestParameters": {},
        "responsestatus": familyDoctor?.status
      }

      let logBody = {
        // message: `User - ${userData?.sub} - Fetch Family Doctor - Doctor Appointment Book New Component`,
        message: rawLogs,
        logGroupName: "ONRx-Patient-Portal",
        logStreamName: `User - ${userData?.sub} - Doctor Appointment Book New Component`,
      }
  
      logEvents(logBody);

      if(familyDoctor?.data?.body === 'Family member not linked.'){}else{

        console.log(familyDoctor,"fafafafafafafafaffa dodododdoccccc");

        setFamilyDoc(familyDoctor?.data?.body?.CompanyEmployee?.CompanyEmployee);
        setCompanyEmpId(familyDoctor?.data?.body?.CompanyEmployee?.CompanyID);
        setFamilyDocList([]);
        getFamilyDoctroList(familyDoctor?.data?.body?.CompanyEmployee?.CompanyID);
      }
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const getFamilyDoctroList = async (emp_id) => {

    if(emp_id){
      try {
        setLoader(true);
        const doctorFamilyData = await UPDATEDGET(`users/doctors/company/${emp_id}`);

        let rawLogs = {
          "userIdentity": {
            "loggedinuser": `${userData?.Email}`,
            "role":`${userData?.role}`,
            "createdate":new Date().toISOString()
          },
          "action": "ACCESS",
          "source": "Doctor Appointment Book New Component",
          "sourceIPAddress": "3.99.184.26",
          "userAgent": userAgent,
          "ipAddress": localStorage.getItem('ipAddress'),
          "apiendpoint": `users/doctors/company/${emp_id}`,
          "requestParameters": {},
          "responsestatus": doctorFamilyData?.status
        }

        let logBody = {
          // message: `User - ${userData?.sub} - Fetch Family Doctor - Doctor Appointment Book New Component`,
          message: rawLogs,
          logGroupName: "ONRx-Patient-Portal",
          logStreamName: `User - ${userData?.sub} - Doctor Appointment Book New Component`,
        }
    
        logEvents(logBody);

        console.log("codtor list by company id", doctorFamilyData);
        setFamilyDocList(doctorFamilyData?.data);
  
        let obj = doctorFamilyData?.data.find(o => o.id === familyDoc.id);
        console.log(obj, "--------final------");
        setFDoc(obj)
  
        setFamilyDocListDummy(doctorFamilyData?.data);
        setLoader(false);
      } catch (error) {
        console.log(error);
        setLoader(false);
      }
    }
  };

  const continueeWithProblem = (item, doc_id) => {
    console.log("plplp")
    if (whichVersion == "with_problem") {
      console.log(item, doc_id);
      let dataForDispatch = {
        user_id: item?.doctor?.EmployeeID,
        companyEmployeeId: item?.doc_id,
        doctor: item?.doctor?.CompanyEmployee,
      };
      dispatch(bookAppointmentDoctor(dataForDispatch));
      dispatch(selectedDoctor(item));

      // history.push("/selectType");
      history.push("/BookAppointment");

    } else if (whichVersion == "without_problem") {
      let dataForDispatch = {
        user_id: item.id,
        companyEmployeeId: item.companyemployeeid,
        doctor: item,
      };
      dispatch(bookAppointmentDoctor(dataForDispatch));
      dispatch(selectedDoctor(item));

      history.push("/BookAppointment");
      // history.push("/selectType");

    }
  };

  const onSearch = (value) => {
    console.log(value, "valuevalue")
    if (whichVersion == "with_problem") {
      setdoctorList(filterItems(value));
    } else {
      setFamilyDocList(filterFamilyDoctor(value));
      setdoctorList(filterItemsDoctor(value));
    }
  };

  const filterItems = (searchTerm) => {
    return doctorListDummy.filter((item) => {
      return (
        item?.doctor?.CompanyEmployee?.FirstName.toLowerCase().indexOf(
          searchTerm.toLowerCase()
        ) > -1
      );
    });
  };

  // const filterItemsDoctor = (searchTerm) => {
  //   console.log(doctorListDummy,"doctorListDummy doctorListDummy")
  //   console.log(searchTerm,"searchTerm searchTerm searchTerm item doc")
  //   return doctorListDummy.filter(item => {
  //     const fullName = `${item.FirstName}${item.MiddleName}${item.LastName}`.toLowerCase();
  //     const DR = `Dr.` + fullName.toLowerCase();
  //     const dr = `dr.` + fullName.toLowerCase();
  //     const DRspace = `Dr` + fullName.toLowerCase();
  //     const drSpace = `dr` + fullName.toLowerCase();
  //     const reversedFullName = `${item.MiddleName}${item.FirstName}`.toLowerCase();
  //     const hospitalName = `${item.clinicName}`.toLowerCase();
  //     const gender = `${item.gender}`.toLowerCase();
  //     const language = `${item.Languages}`.toLowerCase();
  //     const location = `${item.ClinicAddress}`.toLowerCase();
  //     const speciality = `${item.Speciality}`.toLowerCase();
  //     const reverseHospitalName = `${item.clinicName}`.toLowerCase();
  //     // const trimmedSearchValue = searchTerm.replace(/\s+/g, '').toLowerCase();
  //     const trimmedSearchValue = searchTerm.trim().toLowerCase();

  //     if (fullName.includes(trimmedSearchValue) || reversedFullName.includes(trimmedSearchValue) || DR.includes(trimmedSearchValue) || dr.includes(trimmedSearchValue) ||
  //       DRspace.includes(trimmedSearchValue) || drSpace.includes(trimmedSearchValue) ||
  //       hospitalName.includes(trimmedSearchValue) || reverseHospitalName.includes(trimmedSearchValue) || 
  //       gender == trimmedSearchValue || language.includes(trimmedSearchValue) || location.includes(trimmedSearchValue) || speciality.includes(trimmedSearchValue) )

  //       return true;
  //   });
  // }

  // const filterFamilyDoctor = (searchTerm) => {
  //   console.log(familyDocListDummy,"familyDocListDummy familyDocListDummy")
  //   console.log(searchTerm,"searchTerm searchTerm doc fami")
  //   return familyDocListDummy.filter(item => {
  //     const fullName = `${item.FirstName}${item.MiddleName}${item.LastName}`.toLowerCase();
  //     const reversedFullName = `${item.MiddleName}${item.FirstName}`.toLowerCase();
  //     const gender = `${item.gender}`.toLowerCase();
  //     const language = `${item.Languages}`.toLowerCase();
  //     const location = `${item.ClinicAddress}`.toLowerCase();
  //     const speciality = `${item.Speciality}`.toLowerCase();
  //     const trimmedSearchValue = searchTerm.replace(/\s+/g, '').toLowerCase();
  //     return fullName.includes(trimmedSearchValue) || reversedFullName.includes(trimmedSearchValue) || gender.includes(trimmedSearchValue) || language.includes(trimmedSearchValue) || location.includes(trimmedSearchValue) || speciality.includes(trimmedSearchValue);
  //   });
  // }


  const filterItemsDoctor = (searchTerm) => {
    console.log(doctorListDummy,"doctorListDummy doctorListDummy")
    console.log(searchTerm,"searchTerm searchTerm searchTerm item doc")
    return doctorListDummy.filter(item => {
      // console.log("item doc",item)
      const fullName = `${item.FirstName}${item.MiddleName}${item.LastName}`.toLowerCase();
      const fullNameHalf = `${item.FirstName}${item.LastName}`.toLowerCase();
      const DRHalf = `Dr.` + fullNameHalf.toLowerCase();
      const drHalf = `dr.` + fullNameHalf.toLowerCase();
      const DRspaceHalf = `Dr` + fullNameHalf.toLowerCase();
      const drSpaceHalf = `dr` + fullNameHalf.toLowerCase();

      const DR = `Dr.` + fullName.toLowerCase();
      const dr = `dr.` + fullName.toLowerCase();
      const DRspace = `Dr` + fullName.toLowerCase();
      const drSpace = `dr` + fullName.toLowerCase();
      const reversedFullName = `${item.MiddleName}${item.FirstName}`.toLowerCase();
      const hospitalName = `${item.clinicName}`.replace(/\s+/g, '').toLowerCase();
      const gender = `${item.gender}`.toLowerCase();
      const language = `${item.Languages}`.toLowerCase();
      const location = `${item.ClinicAddress}`.replace(/\s+/g, '').toLowerCase();
      const speciality = `${item.Speciality}`.replace(/\s+/g, '').toLowerCase();
      const reverseHospitalName = `${item.clinicName}`.replace(/\s+/g, '').toLowerCase();
      const trimmedSearchValue = searchTerm.replace(/\s+/g, '').toLowerCase();
      // const trimmedSearchValue = searchTerm.trim().toLowerCase();

      console.log(trimmedSearchValue,"trrtr",location)
      if (fullName.includes(trimmedSearchValue) || fullNameHalf.includes(trimmedSearchValue) || reversedFullName.includes(trimmedSearchValue) || DR.includes(trimmedSearchValue) || dr.includes(trimmedSearchValue) || DRHalf.includes(trimmedSearchValue) || drHalf.includes(trimmedSearchValue) ||
        DRspace.includes(trimmedSearchValue) || drSpace.includes(trimmedSearchValue) || DRspaceHalf.includes(trimmedSearchValue) || drSpaceHalf.includes(trimmedSearchValue) ||
        hospitalName.includes(trimmedSearchValue) || reverseHospitalName.includes(trimmedSearchValue) || 
        gender == trimmedSearchValue || language.includes(trimmedSearchValue) || location.includes(trimmedSearchValue) || speciality.includes(trimmedSearchValue) )

        return true;
      });
  }

  const filterFamilyDoctor = (searchTerm) => {
    console.log(familyDocListDummy,"familyDocListDummy familyDocListDummy")
    console.log(searchTerm,"searchTerm searchTerm doc fami")
    return familyDocListDummy.filter(item => {

      const fullName = `${item.FirstName}${item.MiddleName}${item.LastName}`.toLowerCase();
      const fullNameHalf = `${item.FirstName}${item.LastName}`.toLowerCase();
      const DRHalf = `Dr.` + fullNameHalf.toLowerCase();
      const drHalf = `dr.` + fullNameHalf.toLowerCase();
      const DRspaceHalf = `Dr` + fullNameHalf.toLowerCase();
      const drSpaceHalf = `dr` + fullNameHalf.toLowerCase();

      const DR = `Dr.` + fullName.toLowerCase();
      const dr = `dr.` + fullName.toLowerCase();
      const DRspace = `Dr` + fullName.toLowerCase();
      const drSpace = `dr` + fullName.toLowerCase();
      const reversedFullName = `${item.MiddleName}${item.FirstName}`.toLowerCase();
      const hospitalName = `${item.clinicName}`.replace(/\s+/g, '').toLowerCase();
      const gender = `${item.gender}`.toLowerCase();
      const language = `${item.Languages}`.toLowerCase();
      const location = `${item.ClinicAddress}`.replace(/\s+/g, '').toLowerCase();
      const speciality = `${item.Speciality}`.replace(/\s+/g, '').toLowerCase();
      const reverseHospitalName = `${item.clinicName}`.replace(/\s+/g, '').toLowerCase();
      const trimmedSearchValue = searchTerm.replace(/\s+/g, '').toLowerCase();
      // const trimmedSearchValue = searchTerm.trim().toLowerCase();

      console.log(trimmedSearchValue,"trrtr",location)
      if (fullName.includes(trimmedSearchValue) || fullNameHalf.includes(trimmedSearchValue) || reversedFullName.includes(trimmedSearchValue) || DR.includes(trimmedSearchValue) || dr.includes(trimmedSearchValue) || DRHalf.includes(trimmedSearchValue) || drHalf.includes(trimmedSearchValue) ||
        DRspace.includes(trimmedSearchValue) || drSpace.includes(trimmedSearchValue) || DRspaceHalf.includes(trimmedSearchValue) || drSpaceHalf.includes(trimmedSearchValue) ||
        hospitalName.includes(trimmedSearchValue) || reverseHospitalName.includes(trimmedSearchValue) || 
        gender == trimmedSearchValue || language.includes(trimmedSearchValue) || location.includes(trimmedSearchValue) || speciality.includes(trimmedSearchValue) )

        return true;
      });

      // const fullName = `${item.FirstName}${item.MiddleName}${item.LastName}`.toLowerCase();
      // const fullNameHalf = `${item.FirstName}${item.LastName}`.toLowerCase();
      // const reversedFullName = `${item.MiddleName}${item.FirstName}`.toLowerCase();
      // const gender = `${item.gender}`.toLowerCase();
      // const language = `${item.Languages}`.toLowerCase();
      // const location = `${item.ClinicAddress}`.toLowerCase();
      // const speciality = `${item.Speciality}`.toLowerCase();
      // const trimmedSearchValue = searchTerm.replace(/\s+/g, '').toLowerCase();
      // return fullName.includes(trimmedSearchValue) || reversedFullName.includes(trimmedSearchValue) || gender.includes(trimmedSearchValue) || language.includes(trimmedSearchValue) || location.includes(trimmedSearchValue) || speciality.includes(trimmedSearchValue);
    // });
  }

  const getUserImage = (imageUrl) => {
    if (imageUrl?.match(/^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp)(\?(.*))?$/gim) != null) {
      return imageUrl
    }

    console.log(`${process.env.REACT_APP_ASSET_URL}/${imageUrl}`);
    return `${process.env.REACT_APP_ASSET_URL}/${imageUrl}`
  }


  const requestCallBackAndSaveAsAppointment = (item, id) => {
    console.log("item, item?.id: ", item, item?.id);
    const requestedDataforCallBack = {
      patientid: userData.id,
      providerid: whichVersion == "with_problem" ? item.doc_id : item.companyemployeeid,
      startdatetime: setStartTimeBasedOnCallBackRequest(
        appointmentTypeNew.curentAppointment.callback,
        "start"
      ),
      enddatetime: setStartTimeBasedOnCallBackRequest(
        appointmentTypeNew.curentAppointment.callback,
        "end"
      ),
      detail: "NA",
      service: 106,
      status: "PENDING",
      bookedby: userData.id,
      appointmenttype: "CallBack",
      isfamilymember: 0,
      createdate: moment().format("YYYY-MM-DD HH:mm:ss"),
      createdby: userData.id,
      source: "",
    };
    console.log("requestedDataforCallBack: ", requestedDataforCallBack);

    UPDATEDPOST("appointment", requestedDataforCallBack).then(
      (appointmentResponse) => {


        let rawLogs = {
          "userIdentity": {
            "loggedinuser": `${userData?.Email}`,
            "role":`${userData?.role}`,
            "createdate":new Date().toISOString()
          },
          "action": "INSERT",
          "source": "Doctor Appointment Book New Component",
          "sourceIPAddress": "3.99.184.26",
          "userAgent": userAgent,
          "ipAddress": localStorage.getItem('ipAddress'),
          "apiendpoint": "appointment",
          "requestParameters": requestedDataforCallBack,
          "responsestatus": appointmentResponse?.status
        }


        let logBody = {
          // message: `User - ${userData?.sub} - Fetch Family Doctor - Doctor Appointment Book New Component`,
          message: rawLogs,
          logGroupName: "ONRx-Patient-Portal",
          logStreamName: `User - ${userData?.sub} - Doctor Appointment Book New Component`,
        }
    
        logEvents(logBody);

        const appointmentId = appointmentResponse?.data?.insertId;
        if (appointmentId) {
          notification.success({
            message: "Success",
            duration: 2,
            description: "Call back requested successfully",
            onClick: () => {
              console.log("Notification Clicked!");
            },
          });
          history.push("/dashboard");
        } else {
          console.log("error");
          notification.error({
            message: "Something went wrong!",
            duration: 2,
            description: "",
            onClick: () => {
              console.log("Notification Clicked!");
            },
          });
        }
      }
    );
  };

  return (
    <div className={`${style.container1} pt-1 pl-4 pr-4 pb-4`}>
      {loader ? (
        <div className="spinner">
          <Spin tip="Loading..."></Spin>
        </div>
      ) : (
        <div>
          <div className="row">
            <div className="col-md-5 mx-auto">
              <div className="input-group">
                <input
                  onChange={(event) => onSearch(event.target.value)}
                  className="form-control border-end-0 border rounded-pill"
                  type="search"
                  placeholder="Search Doctor"
                  id="example-search-input"
                />
                <div className={style.searchIcon}>
                  <i>
                    <FontAwesomeIcon className="mr-1" icon={faSearch} />
                  </i>
                </div>
              </div>
            </div>
          </div>

          {whichVersion == "with_problem" ? (
            <div className="wrapper">
              <div className="container">
                <div className="row pb-5">
                  {doctorList?.map((item, index) => {
                    return (
                      <React.Fragment>
                        <div key={index} className="col-sm-6 col-md-4 col-lg-3  mt-3">
                          <div className="card mx-30">
                            {item?.doctor?.CompanyEmployee?.profilepicture !=
                              "" &&
                              item?.doctor?.CompanyEmployee?.profilepicture !=
                              null ? (
                              <img
                                // src={
                                //   item?.doctor?.CompanyEmployee?.profilepicture
                                // }
                                src={getUserImage(item?.doctor?.CompanyEmployee?.profilepicture)}
                                onError={(e: any) => {
                                  e.target.onerror = null;
                                  e.target.src =
                                    {IMG01};
                                }}
                                alt=""
                                style={{
                                  height: 120,
                                  width: 120,
                                  borderRadius: "100%",
                                  alignSelf: "center",
                                  marginTop: 25,
                                  objectFit: "cover",
                                }}
                              />
                            ) : (
                              <img
                                src={IMG01}
                                alt=""
                                style={{
                                  height: 120,
                                  width: 120,
                                  borderRadius: "100%",
                                  alignSelf: "center",
                                  marginTop: 25,
                                  objectFit: "cover",
                                }}
                              />
                            )}
                            <div className={style.cardBody}>
                              <h5 className="card-title" style={{ marginBottom: "10px" }}>
                                Dr. {item?.doctor?.CompanyEmployee?.FirstName}{" "}
                                {item.doctor?.CompanyEmployee?.LastName}
                              </h5>
                              {/* <Rate
                                value={2}
                                disabled
                                className="p-0 m-0 pb-1"
                              /> */}
                              <div className={style.ilips}>
                                <span style={{ backgroundColor: 'blueviolet' }} className="badge badge-pill badge-primary"> <p>{item.doctor?.CompanyEmployee?.ClinicName}</p></span>
                              </div>

                              {/* <h6>{item?.doctor?.CompanyEmployee?.Email}</h6> */}
                              <div className={style.wordWrap}>
                                <p
                                  className="card-text"
                                  style={{ fontSize: 12 }}
                                >
                                  {item?.doctor?.CompanyEmployee?.Address1}
                                </p>
                                <div className="div_address">
                                  <p className="" >{item?.ClinicAddress}</p>
                                </div>
                              </div>

                              <div className={style.btns}>
                                <Button
                                  type="default"
                                  className={style.btnBg2}
                                  onClick={() =>
                                    showModal(
                                      item?.doctor?.CompanyEmployee,
                                      item?.doctor?.EmployeeID
                                    )
                                  }
                                >
                                  {" "}
                                  <i style={{ marginRight: 6 }}>
                                    <FontAwesomeIcon
                                      className="mr-1"
                                      icon={faUser}
                                    />
                                  </i>
                                  View Profile
                                </Button>
                                {appointmentTypeNew.curentAppointment
                                  .appointmentType == "CallBackRequest" ? (
                                  <Button
                                    type="default"
                                    className={style.btnBg}
                                    onClick={() =>
                                      requestCallBackAndSaveAsAppointment(
                                        item,
                                        item?.id
                                      )
                                    }
                                  >
                                    {" "}
                                    <i style={{ marginRight: 6 }}>
                                      <FontAwesomeIcon
                                        className="mr-1"
                                        icon={faCalendar}
                                      />
                                    </i>
                                    Request Call Back
                                  </Button>
                                ) : (
                                  <Button
                                    type="default"
                                    className={style.btnBg}
                                    onClick={() =>
                                      continueeWithProblem(item, item?.id)
                                    }
                                  >
                                    {" "}
                                    <i style={{ marginRight: 6 }}>
                                      <FontAwesomeIcon
                                        className="mr-1"
                                        icon={faCalendar}
                                      />
                                    </i>
                                    Book Appointment
                                  </Button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>


                    );
                  })}
                </div>
              </div>
            </div>
          ) : (
            <div className="wrapper">
              <div className="container">
                <div className="row">
                  {familyDocList?.map((item, index) => {
                    return (
                      <React.Fragment>
                        {familyDoc.id == item.id ? (
                          <div
                            key={index}
                            className="col-sm-6 col-md-4 col-lg-3  mt-3 "
                          >
                            <Badge.Ribbon text="Family Doc..">
                              <div className="card mx-30">
                                {item?.profilepicture != "" &&
                                  item?.profilepicture != null && item?.profilepicture != '' ? (
                                  <img
                                    // src={item?.profilepicture}
                                    src={getUserImage(item?.profilepicture)}
                                    onError={(e: any) => {
                                      e.target.onerror = null;
                                      e.target.src =
                                        {IMG01};
                                    }}
                                    alt=""
                                    style={{
                                      height: 120,
                                      width: 120,
                                      borderRadius: "100%",
                                      alignSelf: "center",
                                      marginTop: 25,
                                      objectFit: 'cover'
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={IMG01}
                                    alt=""
                                    style={{
                                      height: 120,
                                      width: 120,
                                      borderRadius: "100%",
                                      alignSelf: "center",
                                      marginTop: 25,
                                      objectFit: "cover",
                                    }}
                                  />
                                )}
                                <div className={style.cardBody}>
                                  <h5 className="card-title" style={{ marginBottom: "10px" }}>
                                    Dr. {item?.FirstName} {item.MiddleName}{" "}
                                    {item.LastName}
                                    {/* Dr. {item?.doctor?.CompanyEmployee?.FirstName} {item.doctor?.CompanyEmployee?.LastName} */}
                                  </h5>
                                  {/* {
                                    item?.Department ?
                                      <h6>{item?.Department}</h6> :
                                      <h6>Department</h6>
                                  } */}
                                  {/* <Rate value={2} className="p-0 m-0 pb-1" /> */}
                                  <div className={style.wordWrap}>

                                    <div className={style.ilips}>
                                      <span style={{ backgroundColor: 'blueviolet' }} className="badge badge-pill badge-primary">{item?.clinicName}</span>
                                      {/* <p>{item?.clinicName}</p> */}
                                    </div>
                                    {/* <h6>{item?.Email}</h6> */}
                                    <p
                                      className="card-text"
                                      style={{ fontSize: 12 }}
                                    >
                                      {item?.Address1}
                                    </p>
                                    <div className="div_address">
                                      <p className="p-0 m-0" >{item?.ClinicAddress}</p>
                                    </div>
                                  </div>

                                  <div className={style.btns}>
                                    <Button
                                      type="default"
                                      className={style.btnBg2}
                                      // onClick={() => showModal(item, item?.doctor?.EmployeeID)}
                                      onClick={() => showModal(item, item?.id)}
                                    >
                                      {" "}
                                      <i style={{ marginRight: 6 }}>
                                        <FontAwesomeIcon
                                          className="mr-1"
                                          icon={faUser}
                                        />
                                      </i>
                                      View Profile
                                    </Button>
                                    {appointmentTypeNew.curentAppointment
                                      .appointmentType == "CallBackRequest" ? (
                                      <Button
                                        type="default"
                                        className={style.btnBg}
                                        onClick={() =>
                                          requestCallBackAndSaveAsAppointment(
                                            item,
                                            item?.id
                                          )
                                        }
                                      >
                                        {" "}
                                        <i style={{ marginRight: 6 }}>
                                          <FontAwesomeIcon
                                            className="mr-1"
                                            icon={faCalendar}
                                          />
                                        </i>
                                        Request Call Back
                                      </Button>
                                    ) : (
                                      <Button
                                        type="default"
                                        className={style.btnBg}
                                        onClick={() =>
                                          continueeWithProblem(item, item?.id)
                                        }
                                      >
                                        {" "}
                                        <i style={{ marginRight: 6 }}>
                                          <FontAwesomeIcon
                                            className="mr-1"
                                            icon={faCalendar}
                                          />
                                        </i>
                                        Book Appointment
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Badge.Ribbon>
                          </div>
                        ) : (
                          <div className="col-sm-6 col-md-4 col-lg-3  mt-3 ">
                            <div className="card mx-30">
                              {item?.profilepicture != "" &&
                                item?.profilepicture != null || item?.profilepicture != '' ? (
                                <img
                                  // src={item?.profilepicture}
                                  src={getUserImage(item?.profilepicture)}
                                  onError={(e: any) => {
                                    e.target.onerror = null;
                                    e.target.src =
                                      {IMG01};
                                  }}
                                  alt=""
                                  style={{
                                    height: 120,
                                    width: 120,
                                    borderRadius: "100%",
                                    alignSelf: "center",
                                    marginTop: 25,
                                    objectFit: 'cover'
                                  }}
                                />
                              ) : (
                                <img
                                  src={IMG01}
                                  alt=""
                                  style={{
                                    height: 120,
                                    width: 120,
                                    borderRadius: "100%",
                                    alignSelf: "center",
                                    marginTop: 25,
                                  }}
                                />
                              )}
                              <div className={style.cardBody}>
                                <h5 className="card-title" style={{ marginBottom: "10px" }}>
                                  Dr. {item?.FirstName} {item.MiddleName}{" "}
                                  {item.LastName}
                                  {/* Dr. {item?.doctor?.CompanyEmployee?.FirstName} {item.doctor?.CompanyEmployee?.LastName} */}
                                </h5>
                                {/* {
                                    item?.Department ?
                                      <h6>{item?.Department}</h6> :
                                      <h6>Department</h6>
                                  } */}
                                {/* <Rate value={2} className="p-0 m-0 pb-1" /> */}
                                <div className={style.ilips}>
                                  <span style={{ backgroundColor: 'blueviolet' }} className="badge badge-pill badge-primary">{item?.clinicName}</span>
                                  {/* <p>{item?.clinicName}</p> */}
                                </div>
                                {/* <h6>{item?.Email}</h6> */}
                                <div className={style.wordWrap}>
                                  <p
                                    className="card-text"
                                    style={{ fontSize: 12 }}
                                  >
                                    {item?.Address1}
                                  </p>
                                  <div className={style.div_address}>
                                    <p className="" >{item?.ClinicAddress}</p>
                                  </div>
                                </div>

                                <div className={style.btns}>
                                  <Button
                                    type="default"
                                    className={style.btnBg2}
                                    // onClick={() => showModal(item, item?.doctor?.EmployeeID)}
                                    onClick={() => showModal(item, item?.id)}
                                  >
                                    {" "}
                                    <i style={{ marginRight: 6 }}>
                                      <FontAwesomeIcon
                                        className="mr-1"
                                        icon={faUser}
                                      />
                                    </i>
                                    View Profile
                                  </Button>
                                  {appointmentTypeNew.curentAppointment
                                    .appointmentType == "CallBackRequest" ? (
                                    <Button
                                      type="default"
                                      className={style.btnBg}
                                      onClick={() =>
                                        requestCallBackAndSaveAsAppointment(
                                          item,
                                          item?.id
                                        )
                                      }
                                    >
                                      {" "}
                                      <i style={{ marginRight: 6 }}>
                                        <FontAwesomeIcon
                                          className="mr-1"
                                          icon={faCalendar}
                                        />
                                      </i>
                                      Request Call Back
                                    </Button>
                                  ) : (
                                    <Button
                                      type="default"
                                      className={style.btnBg}
                                      onClick={() =>
                                        continueeWithProblem(item, item?.id)
                                      }
                                    >
                                      {" "}
                                      <i style={{ marginRight: 6 }}>
                                        <FontAwesomeIcon
                                          className="mr-1"
                                          icon={faCalendar}
                                        />
                                      </i>
                                      Book Appointment
                                    </Button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>

                {doctorList.length != 0 ? (
                  <>
                    <div>
                      <p className={`${style.docName} pt-4 m-0 `}>
                        Other Clinic Doctor
                      </p>
                    </div>
                    <hr />
                  </>
                ) : null}

                <div className="row pb-5">
                  {doctorList?.map((item, index) => {
                    return (
                      <React.Fragment>
                        {companyEmpId != item?.CompanyID ? (
                          <div
                            key={index}
                            className="col-sm-6 col-md-4 col-lg-3  mt-3 "
                          >
                            <div className="card mx-30">
                              {item?.profilepicture != "" &&
                                item?.profilepicture != null ? (
                                <img
                                  // src={item?.profilepicture}
                                  src={getUserImage(item?.profilepicture)}
                                  onError={(e: any) => {
                                    e.target.onerror = null;
                                    e.target.src =
                                      {IMG01};
                                  }}
                                  alt=""
                                  style={{
                                    height: 120,
                                    width: 120,
                                    borderRadius: "100%",
                                    alignSelf: "center",
                                    marginTop: 25,
                                    objectFit: "cover",
                                  }}
                                />
                              ) : (
                                <img
                                  src={IMG01}
                                  alt=""
                                  style={{
                                    height: 120,
                                    width: 120,
                                    borderRadius: "100%",
                                    alignSelf: "center",
                                    marginTop: 25,
                                    objectFit: "cover",
                                  }}
                                />
                              )}
                              <div className={style.cardBody} style={{ marginBottom: "10px" }}>
                                <h5 className="card-title" style={{ marginBottom: "10px" }} >
                                  Dr. {item?.FirstName} {item.MiddleName}{" "}
                                  {item.LastName}
                                </h5>
                                {/* {
                                    item?.Department ?
                                      <h6>{item?.Department}</h6> :
                                      <h6>Department</h6>
                                  } */}
                                {/* <Rate value={2} className="p-0 m-0 pb-1" /> */}
                                {/* <p>{item?.clinicName}</p> */}
                                <div className={style.ilips}>
                                  <span style={{ backgroundColor: 'blueviolet' }} className="badge badge-pill badge-primary">{item?.clinicName}</span>
                                  {/* <p>{item?.clinicName}</p> */}
                                </div>

                                {/* <h6>{item?.Email}</h6> */}

                                <div className={style.wordWrap}>
                                  <p
                                    className="card-text"
                                    style={{ fontSize: 12 }}
                                  >
                                    {item?.Address1}
                                  </p>
                                  {/* <div className={style.div_address}>
                                    <p className="" >{item?.ClinicAddress}</p>
                                  </div> */}
                                </div>

                                <div className={style.btns}>
                                  <Button
                                    type="default"
                                    className={style.btnBg2}
                                    onClick={() => showModal(item, item?.id)}
                                  >
                                    {" "}
                                    <i style={{ marginRight: 6 }}>
                                      <FontAwesomeIcon
                                        className="mr-1"
                                        icon={faUser}
                                      />
                                    </i>
                                    View Profile
                                  </Button>
                                  {appointmentTypeNew.curentAppointment
                                    .appointmentType == "CallBackRequest" ? (
                                    <Button
                                      type="default"
                                      className={style.btnBg}
                                      onClick={() =>
                                        requestCallBackAndSaveAsAppointment(
                                          item,
                                          item?.id
                                        )
                                      }
                                    >
                                      {" "}
                                      <i style={{ marginRight: 6 }}>
                                        <FontAwesomeIcon
                                          className="mr-1"
                                          icon={faCalendar}
                                        />
                                      </i>
                                      Request Call Back
                                    </Button>
                                  ) : (
                                    <Button
                                      type="default"
                                      className={style.btnBg}
                                      onClick={() =>
                                        continueeWithProblem(item, item?.id)
                                      }
                                    >
                                      {" "}
                                      <i style={{ marginRight: 6 }}>
                                        <FontAwesomeIcon
                                          className="mr-1"
                                          icon={faCalendar}
                                        />
                                      </i>
                                      Book Appointment
                                    </Button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
          )}

          <div className="row">
            <Modal
              width={900}
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={null}
            >
              <DoctorProfile />
            </Modal>
          </div>

          <div className="row" style={{ width: "100%", marginRight: 0 }}></div>
        </div>
      )}
    </div>
  );
};

import { Link, useHistory } from "react-router-dom";
import { notification, Steps, Select, Spin, Modal } from "antd";
import moment, { utc } from "moment";
import { useDispatch, useSelector } from "react-redux";
import style from "./style.module.scss";
import { useEffect, useState } from "react";
import { UPDATEDGET, UPDATEDPOST } from "../../services/common.api";
import {
  setStartTimeBasedOnCallBackRequest,
  setStartTimeBasedOnCallBackRequestWithDate,
  setStartTimeBasedOnCallBackRequestWithDateAndTime
} from "../../utils/common";
import SweetAlert from "react-bootstrap-sweetalert";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';
import store from 'store'
import PageHeader from "components/pageHeader";
import { TopBar } from "components";
import config from "config";

// const config = {
//   BASEURL: process.env.REACT_APP_API_URL,
//   paymentUrl: process.env.REACT_APP_PAYMENT_API,
//   applicationid: process.env.REACT_APP_SQUARE_APPLICATION_ID,
//   locationid: process.env.REACT_APP_SQUARE_LOCATION_ID
// }

const PaymentSummary = () => {
  const { Step } = Steps;
  const [isLoad, setLoad] = useState(false);
  const history = useHistory();
  const currentAppointmentInfo = useSelector(
    (state: any) => state.curentAppointment
    );
    
    const [paymentClick, setPaymentClick] = useState(false);
  const App_Type = useSelector(
    (state: any) => state.curentAppointment.appointmentRequestType
  );

  const familyMemberState: any = useSelector(
    (state: any) => state?.curentAppointment?.familyMember
  );

  const user = useSelector((state: any) => state.userReducer.user);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  // const [DoctorDetail, setDoctorDetail] = useState({} as any);
  const [formValues, setFormValues] = useState({} as any);
  const [familyMemberDetails, setFamilyMemberDetails] = useState({} as any);
  const [selectedTime, setTime] = useState("");
  const [term, setTerm] = useState(false);
  const [selectedDate, setDate] = useState("");
  const [dateAndTime, setDateAndTime] = useState("");
  const [chooseServices, setChooseServices] = useState(false);
  const [hideAlert, setHideAlert] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const appointmentTypeNew = useSelector((state: any) => state);
  const [selectedServiceByUser, setSeletedService] = useState({} as any);
  // const [comment, setComment] = useState("");
  const [loader, setLoader] = useState(false);
  const [userAgreementModal, setUserAgreementModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [amount, setAmount] = useState(0);
  const dispatch = useDispatch();

  const currentAppointmentState: any = useSelector(
    (state: any) => state?.curentAppointment?.appointmentRequestType
  );

  console.log(currentAppointmentState, "currentAppointmentStatecurrentAppointmentStatecurrentAppointmentState")

  const handleChange = (event) => {
    console.log(event);
    const name = event.target.name;
    const value = event.target.value;
    console.log(name, value);
    setFormValues((formValues) => ({ ...formValues, [name]: value }));
    setErrMsg(false);
  };

  const goToBack = () => {
    history.goBack();
  };
    
    useEffect(() => {
      

      console.log(process.env,"applicationid")


    setLoad(true);
    getPatientDetails();
    console.log(currentAppointmentInfo, "******currentAppointmentInfo");
    setAmount(currentAppointmentInfo?.selectedService?.serviceCost);

    if (App_Type !== "familyMember") {
      setFormValues((formValues) => ({
        ...formValues,
        firstname: user.FirstName,
      }));
      setFormValues((formValues) => ({
        ...formValues,
        lastname: user.LastName,
      }));
      setFormValues((formValues) => ({ ...formValues, email: user.Email }));
      setFormValues((formValues) => ({
        ...formValues,
        phone: user.phoneNumber,
      }));
    } else {
      var s =
        currentAppointmentInfo?.familyMember?.userName.split(/[ ,]+/);
      console.log(s);
      if (s) {
        setFormValues((formValues) => ({ ...formValues, firstname: s[0] }));
        setFormValues((formValues) => ({ ...formValues, lastname: s[1] }));
      }

      setFormValues((formValues) => ({
        ...formValues,
        email: familyMemberDetails?.email,
      }));
      setFormValues((formValues) => ({
        ...formValues,
        phone: familyMemberDetails?.phoneNumber,
      }));
    }

    setTime(currentAppointmentInfo.selectedService.selectedTime);

  }, []);

  const squarePayment = isLoad ? (
    // <PaymentForm 
    //   history={history}
    //   paymentForm={window.SqPaymentForm} 
    //   where="fromCallback"
    //   amount={amount} 
    //   user={user}
    //   appoimentDate={currentAppointmentInfo.selectedService?.selectedDate}
    //   time={selectedTime}
    //   selectslot={currentAppointmentInfo.selectedService?.selectslot}
    //   selectedService={currentAppointmentInfo.selectedService}
    // />

<PaymentForm
      /**
       * Identifies the calling form with a verified application ID generated from
       * the Square Application Dashboard.
       */
      // applicationId="sandbox-sq0idb-xsjhqKMUXbE4V1kC7STkFw"
      // locationId="L4ZD3QVYFXK93"
      applicationId={config?.applicationId}
      locationId={config?.locationId}
      
      /**
        * Invoked when payment form receives the result of a tokenize generation
        * request. The result will be a valid credit card or wallet token, or an error.
        */
      cardTokenizeResponseReceived={(token, buyer) => {
        console.log("ahiii token",token?.token);

        const paymentUrl = config?.paymentUrl;

        if(token?.token){
          setPaymentClick(true);
          fetch(paymentUrl, {
            method: 'POST',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            },
            body: JSON.stringify({
            nonce: token?.token,
            amount : currentAppointmentInfo?.selectedService?.serviceCost * 100
            })
          })
          .catch(err => {
            setPaymentClick(false);
            alert('Network error: ' + err);
          })
          .then((response : any) => {
              if (!response.ok) {
              return response.text().then(errorInfo => Promise.reject(errorInfo));
              }
              return response.text();
          })
          .then((data : any) => {
              console.log(JSON.parse(data),"result of payment"); 
              requestCallbackAppointment(JSON.parse(data)?.result?.payment);
          })
          .catch(err => {
              console.error(err);
              setPaymentClick(false);
              notification.error({
                message: "'Payment failed to complete!",
              });
          });
        }
      }}
      /**
       * This function enable the Strong Customer Authentication (SCA) flow
       *
       * We strongly recommend use this function to verify the buyer and reduce
       * the chance of fraudulent transactions.
       */
      createVerificationDetails={() => ({
        /* omit amount key if you want to store the card details */
        amount: currentAppointmentInfo?.selectedService?.serviceCost+'',
        /* collected from the buyer */
        billingContact: {
          addressLines: [user?.Address1],
          familyName: user?.FirstName + " " + user?.LastName,
          givenName: user?.FirstName + " " + user?.LastName,
          countryCode: 'CA',
          city: user?.City,
        },
        currencyCode: 'CAD',
        intent: 'CHARGE',
      })}
    >

      <div className="form-group">
        <label htmlFor="nameOnCard">Name on card</label>
        <input type="text" className="form-control" style={{fontWeight: 400,fontSize: 16}} id="nameOnCard" name="nameOnCard" />
      </div>

      <CreditCard
        postalCode="false"
        callbacks={{
          submit(event) {
            console.info("submit: ", event);
          }
        }}
        focus="cardNumber"
        buttonProps={{
          isLoading: paymentClick,
        }}
        onClick={() => {
          console.log("payment button clicked");
        }}
      >
        Submit Payment
      </CreditCard>
    </PaymentForm>

  ) : null;

  const requestCallbackAppointment = (paymentId) => {

    let UserId;
    if (currentAppointmentState == "Own") {
      UserId = user.id;
    } else {
      UserId = familyMemberState?.familymemberid;
    }

    const requestedDataforCallBack = {
      // patientid: user?.id,
      patientid: UserId,
      callbackstatus: "NEW",
      paymenttype: "PRIVATE_PAY",
      service : currentAppointmentInfo.selectedService?.serviceId,
      callbacktime: selectedTime,
      doctorid: null,
      created_at: currentAppointmentInfo.selectedService?.selectedDate,
      updated_at: currentAppointmentInfo.selectedService?.selectedDate,
      updated_by: user.id,
      created_by: user.id,
      vitalsnote: currentAppointmentInfo.selectedService?.vitalsnote,
      callbackreason: currentAppointmentInfo.selectedService?.selectedServiceName,
      callbackdate: moment(currentAppointmentInfo.selectedService?.selectedDate, "YYYY-MM-DD HH:mm:ss").format(
        "YYYY-MM-DD"
      ),
      comments: "NA",
      paymentid: paymentId?.id,
      recept_number : paymentId?.receipt_number,
      callbackstart: moment(setStartTimeBasedOnCallBackRequestWithDateAndTime(
        currentAppointmentInfo.selectedService?.selectslot,
        "start",
        moment(currentAppointmentInfo.selectedService?.selectedDate, "YYYY-MM-DD HH:mm:ss").format(
          "dddd, MMM DD, YYYY"
        )
      ), "YYYY-MM-DD HH:mm A").local().format("YYYY-MM-DD HH:mm A"),
      callbackend: moment(setStartTimeBasedOnCallBackRequestWithDateAndTime(
        currentAppointmentInfo.selectedService?.selectslot,
        "end",
        moment(currentAppointmentInfo.selectedService?.selectedDate, "YYYY-MM-DD HH:mm:ss").format(
          "dddd, MMM DD, YYYY"
        )
      ), "YYYY-MM-DD HH:mm A").local().format("YYYY-MM-DD HH:mm A"),
    };

    console.log("requestedDataforCallBack: ", requestedDataforCallBack);
    
    fetch(config?.apiUrl + 'callbackrequests', {
        method: 'POST',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${store.get("auth_token_refresh")}`
        },
        body: JSON.stringify(requestedDataforCallBack)
      })
      .catch(err => {
        setPaymentClick(false);
        alert('Network error: ' + err);
      })
      .then((response : any) => {
          if (!response.ok) {
          return response.text().then(errorInfo => Promise.reject(errorInfo));
          }
          return response.text();
      })
      .then(data => {
        console.log(data,"apointment created");
        setPaymentClick(false);
        notification.success({
          message: "Payment complete successfully!",
        });

        history.push('/dashboard');
    })
    .catch(err => {
      setPaymentClick(false);
      console.error(err);
    });
  }

  useEffect(() => {
    if (App_Type !== "familyMember") {
      setFormValues((formValues) => ({
        ...formValues,
        firstname: user.FirstName,
      }));
      setFormValues((formValues) => ({
        ...formValues,
        lastname: user.LastName,
      }));
      setFormValues((formValues) => ({ ...formValues, email: user.Email }));
      setFormValues((formValues) => ({
        ...formValues,
        phone: user.phoneNumber,
      }));
    } else {
      var s =
        currentAppointmentInfo?.familyMember?.userName.split(/[ ,]+/);
      console.log(s);
      setFormValues((formValues) => ({ ...formValues, firstname: s[0] }));
      setFormValues((formValues) => ({ ...formValues, lastname: s[1] }));
      setFormValues((formValues) => ({
        ...formValues,
        email: familyMemberDetails?.email,
      }));
      setFormValues((formValues) => ({
        ...formValues,
        phone: familyMemberDetails?.phoneNumber,
      }));
    }
  }, [familyMemberDetails]);

  const getPatientDetails = async () => {
    if (currentAppointmentInfo?.familyMember?.familymemberid) {
      try {
        const data = await UPDATEDGET(
          `user/profile/${currentAppointmentInfo?.familyMember?.familymemberid}`
        );
        console.log(data.data[0][0], "Family data");
        setFamilyMemberDetails(data.data[0][0]);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    console.log("Clicked")
    setPaymentModal(true);

    const consentData = {
      added_by: user?.id,
      consent_page: "Payment Summary",
      consent_name: "Terms & Conditions",
      timestemp: moment().format('YYYY-MM-DD HH:mm:ss')
    };

    const Consent = await UPDATEDPOST("consent", consentData);

    console.log(Consent, "Consent");

    // if (
    //   formValues.firstname != "" &&
    //   formValues.formValues != ""
    // ) {
    //   if (term == true) {
    //     // Call api here
    //   } else {
    //     notification.error({
    //       message: "Error",
    //       duration: 2,
    //       description: "Please accept Terms & Conditions to continue.",
    //       onClick: () => {
    //         console.log("Notification Clicked!");
    //       },
    //     });
    //   }
    // } else {
    //   setErrMsg(true);
    //   notification.error({
    //     message: "Error",
    //     duration: 2,
    //     description: "Please Enter all the mandatory fields .",
    //   });
    // }
  };

  const termsAndConditions = (event: any) => {
    console.log(event.target.checked);
    setTerm(event.target.checked);
  };

  const cancelBack = () => {
    setHideAlert(true);
  };
  const handleBack = () => {
    setHideAlert(false);
    history.push("/dashboard");
  };
  const onCancel = () => {
    setHideAlert(false);
  };

  return (
    <div>
      <TopBar  isBookAppointment={true} />

      {/* <div className="box" style={{ marginTop: 0 }}>
        <p className="pageInfo">Checkout</p>
        <h5 className="h5New">
          <Link style={{ color: "white" }} to="/dashboard">
            Dashboard
          </Link>{" "}
          / Checkout
        </h5>
      </div> */}
      
      <div>
        <div>
          <div className="content contentSelect" >
            <div className="container-fluid">
            <PageHeader
            items={[
              { label: "Dashboard", link: "/dashboard" },
              { label: "Checkout", link: "" },
             
            ]}
            pageTitle = "Checkout"
          />
            </div>
         

            <div className="container">

            
              <div className="row">
                <div className="col-sm-12 col-md-12 col-xl-12">
                
                  <div className="stepDiv d-none d-sm-block">
                    <Steps size="small">
                      <Step status="finish" title="Payment" />
                      <Step status="finish" title="Select Type" />
                      <Step status="finish" title="Select Slot" />
                      <Step status="finish" title="Book Appointment" />
                    </Steps>
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <div className={`col-md-7 col-lg-8 ${style.div_form}`}>
                  <div className="card">
                    <div className="card-body">
                      <form onSubmit={handleSubmit}>
                        <div className="info-widget pb-4">
                          <h4 className="card-title">Personal Information</h4>
                          <div className="row mb-0">
                            <div className="col-md-6 col-sm-12">
                              <div className="form-group card-label">
                                <label>First Name</label>
                                <input
                                  disabled={true}
                                  type="text"
                                  className="form-control"
                                  name="firstname"
                                  value={formValues.firstname || ""}
                                  onChange={handleChange}
                                  style={{ cursor: "not-allowed", backgroundColor: "#FAFAFA", color: "lightgray" }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                              <div className="form-group card-label">
                                <label>Last Name</label>
                                <input
                                  disabled={true}
                                  type="text"
                                  className="form-control"
                                  name="lastname"
                                  value={formValues.lastname || ""}
                                  onChange={handleChange}
                                  style={{ cursor: "not-allowed", backgroundColor: "#FAFAFA", color: "lightgray" }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                              <div className="form-group card-label">
                                <label>Email</label>
                                <input
                                  disabled={
                                    App_Type != "familyMember" ? true : false
                                  }
                                  className="form-control"
                                  type="email"
                                  name="email"
                                  value={formValues.email || ""}
                                  onChange={handleChange}
                                  style={{
                                    cursor:
                                      App_Type == "familyMember"
                                        ? "default"
                                        : "not-allowed",
                                    backgroundColor: App_Type == "familyMember" ? "#ffffff" : "#FAFAFA",
                                    color: App_Type == "familyMember" ? "#000000" : "lightgray",
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                              <div className="form-group card-label" style={{ marginBottom: "5px" }}>
                                <label>Phone</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="phone"
                                  value={formValues.phone || ""}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>


                        {/* <div className="payment-widget info-widget p-0 m-0">                        
                          <div>
                            <div className="payment-list">
                              <h4 className="card-title">Credit Card Information</h4>
                              {squarePayment}
                            </div>
                          </div>
                        </div>  */}

                        <div className="terms-accept">
                          <div>
                            <Checkbox
                              onChange={termsAndConditions}
                              style={{ marginTop: 4 }}
                            >
                              I have read and accept{" "}
                            </Checkbox>
                            <span>
                              <a
                                target="_blank"
                                href="https://onhealthalliance.com/terms-of-use/"
                                // onClick={() => setUserAgreementModal(true)}
                                className="pt-1"
                               
                              >
                                Terms &amp; Conditions
                              </a>
                            </span>
                          </div>
                        </div>

                        <div
                          className="submit-section mt-4 row"
                          style={{ marginTop: 20, marginLeft: 6 }}
                        >
                          <div className={style.btnContainer}>
                            <div className={style.btnBox1}>
                              <button
                                type="button"
                                className={`${style.btnBg1} mr-3`}
                                onClick={() => goToBack()}
                              >
                                Previous
                              </button>
                              {loader ? <Spin /> :
                                <button
                                  type="submit"
                                  className={`btn btn-primary ${style.submitBtn}`}
                                  disabled={!term}
                                >
                                  Schedule
                                </button>
                              }
                            </div>
                            <div className={style.btnBox2}>
                              <button
                                type="button"
                                className={`${style.btnBg2} mx-3`}
                                onClick={() => cancelBack()}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>

                    {/* {squarePayment} */}


                  </div>
                </div>

                <div className="col-md-5 col-lg-4 theiaStickySidebar">
                  <div className="card booking-card">
                    <div className="card-header">
                      <h4 className="card-title">Booking Summary</h4>
                    </div>
                    <div className="card-body">
                      <div className="booking-summary">
                        <div className="booking-item-wrap">
                          <ul className="booking-date">
                            <li>
                              Date{" "}
                              <span>
                                {moment(
                                  currentAppointmentInfo.selectedService
                                    ?.selectedDate
                                ).format("DD-MMM-YYYY")}
                              </span>
                            </li>
                            {appointmentTypeNew.curentAppointment
                              .appointmentType == "callback" ? (
                              <li>
                                Time{" "}
                                <span>
                                  {
                                    appointmentTypeNew.curentAppointment
                                      .callback
                                  }
                                </span>
                              </li>
                            ) : (
                              <li>
                                Time{" "}
                                <span>
                                  {currentAppointmentInfo.selectedService?.selectslot}
                                  {/* {moment(selectedTime, "HH:mm:ss").format(
                                    "hh:mma"
                                  )} */}
                                </span>
                              </li>
                            )}
                          </ul>
                          <ul className="booking-fee">
                            <li>
                              Booking Fee{" "}
                              <span>
                                ${amount}
                                {/* {
                                  currentAppointmentInfo.selectedService
                                    .serviceCost
                                } */}
                              </span>
                            </li>

                            {/* {currentAppointmentInfo?.selectedService?.name !==
                              undefined ? ( */}
                            <li>
                              Services
                              <span>
                                {currentAppointmentInfo.selectedService.selectedServiceName}
                              </span>
                            </li>
                            {/* ) : null} */}

                            <li>
                              Payment Provider{" "}
                              <span>
                                Private Pay
                              </span>
                            </li>
                          </ul>
                          <div className="booking-total">
                            <ul className="booking-total-list">
                              <li>
                                <span>Total</span>
                                <span className="total-cost">
                                  {" "}
                                  ${amount}
                                  {/* $ Total here */}
                                  {/* {
                                    currentAppointmentInfo.selectedService
                                      .serviceCost
                                  } */}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Payment"
        centered
        visible={paymentModal}
        onOk={() => setPaymentModal(false)}
        onCancel={() => setPaymentModal(false)}
        footer={null}
      >
        {squarePayment}
      </Modal>


      <Modal
        centered
        visible={userAgreementModal}
        width={1000}
        onOk={() => setUserAgreementModal(false)}
        onCancel={() => setUserAgreementModal(false)}
        footer={null}
      >
        <div className="pt-4">
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
            <Viewer
              fileUrl='https://cf2c9230-6450-4364-9252-7d656ea6986d.filesusr.com/ugd/911847_05eee2ae00b84bd9a94262e36b211932.pdf'
              plugins={[
                defaultLayoutPluginInstance,
              ]}
            />
          </Worker>
        </div>
      </Modal>

      {hideAlert && (
        <SweetAlert
          warning
          title="Are you sure?"
          onConfirm={handleBack}
          onCancel={onCancel}
          showCancel={true}
          confirmBtnText="Yes"
          cancelBtnText="No"
        />
      )}
    </div>
  );
};

export default PaymentSummary;

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const allMessage = /* GraphQL */ `
  query AllMessage($after: String, $conversationId: String!, $first: Int) {
    allMessage(after: $after, conversationId: $conversationId, first: $first) {
      author
      authorName
      authorType
      content
      conversationId
      createdAt
      id
      __typename
    }
  }
`;
export const getConversation = /* GraphQL */ `
  query GetConversation($after: String, $first: Int) {
    getConversation(after: $after, first: $first) {
      appointmentId
      createdAt
      doctor
      doctorName
      id
      patient
      patientName
      staff
      staffName
      __typename
    }
  }
`;
export const getConversationByDoctorPatient = /* GraphQL */ `
  query GetConversationByDoctorPatient($doctor: String!, $patient: String!) {
    getConversationByDoctorPatient(doctor: $doctor, patient: $patient) {
      appointmentId
      createdAt
      doctor
      doctorName
      id
      patient
      patientName
      staff
      staffName
      __typename
    }
  }
`;
export const getConversationById = /* GraphQL */ `
  query GetConversationById($id: String!) {
    getConversationById(id: $id) {
      appointmentId
      createdAt
      doctor
      doctorName
      id
      patient
      patientName
      staff
      staffName
      __typename
    }
  }
`;
export const getDoctorConversation = /* GraphQL */ `
  query GetDoctorConversation($doctor: String!) {
    getDoctorConversation(doctor: $doctor) {
      appointmentId
      createdAt
      doctor
      doctorName
      id
      patient
      patientName
      staff
      staffName
      __typename
    }
  }
`;
export const getPatientConversation = /* GraphQL */ `
  query GetPatientConversation($patient: String!) {
    getPatientConversation(patient: $patient) {
      appointmentId
      createdAt
      doctor
      doctorName
      id
      patient
      patientName
      staff
      staffName
      __typename
    }
  }
`;

import style from "./style.module.css";
import { Link, useHistory } from "react-router-dom";
import { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import ClinicComponent from "components/Clinic";

const Clinic = () => {
  const history = useHistory();
  const [hideAlert, setHideAlert] = useState(false);
  const [logo, setLogo] = useState(null); 

  const handleBack = () => {
    setHideAlert(false);
    history.push("/");
  };
  const onCancel = () => {
    setHideAlert(false);
  };

  return (
    <div className={style.main_container}>
      {hideAlert == true ? (
        <SweetAlert
          success
          title="Signup successful!"
          onConfirm={handleBack}
          onCancel={onCancel}
          confirmBtnText="Go Login"
        >
          Please check your Email and Validate your Account
        </SweetAlert>
      ) : null}
      <div className={style.row}>
        <div className={style.col}>
          <div className={style.leftWrapper}>
            <img
              src="Images/logo.png"
              className={style.imgFluid}
              alt="Doccure Login"
            />
            <h1 className="mt-3"  style={{ color: "var(--primary-color)" }}>
              Sign Up
            </h1>
            <div className="container ">
              <ClinicComponent setLogo={setLogo} />
            </div>
            <div className={`text-center ${style.dontHave}`}>
              Already Have Account ?
              <Link to="/login" style={{ color: "var(--primary-color)" ,fontWeight:'500' }}>
                {" "}
                Login Now
              </Link>
            </div>
            <div className={`text-center ${style.copy}`}>© 2024 ONRx. All rights reserved.</div>
          </div>
        </div>

        <div className={style.col} style={{ padding: "20px" }}>
          <div className={style.imgStyle}>
            <img height="100" src={logo} alt="" className={style.img_logo_dr} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clinic;

import { Link, useHistory } from "react-router-dom";
import { TopBar } from "components";
import { notification, Rate, Steps, Select, Spin, Modal } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
import moment, { utc } from "moment";
import { useDispatch, useSelector } from "react-redux";
import style from "./style.module.scss";
import { useEffect, useState } from "react";
import { UPDATEDGET, UPDATEDPOST } from "../../services/common.api";
import { selectAppointmentType, selectedService, selectPaymentProvider } from "../../redux/actions/userActions";
import SweetAlert from "react-bootstrap-sweetalert";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import Checkbox from "antd/lib/checkbox/Checkbox";
import store from 'store'
// import PaymentForm from "components/paymentForm/paymentForm";
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';
import { IMG01 } from "./img";
import PageHeader from "components/pageHeader";
import { isMobile, isTablet, isBrowser } from 'react-device-detect';


const config = {
  BASEURL: process.env.REACT_APP_API_URL,
  paymentUrl: process.env.REACT_APP_PAYMENT_API,
  applicationid: process.env.REACT_APP_SQUARE_APPLICATION_ID,
  locationid: process.env.REACT_APP_SQUARE_LOCATION_ID
}

const Checkout = () => {
  const { Step } = Steps;
  const { Option } = Select;
  const history = useHistory();
  // const SelectedDoctor = useSelector((state: any) => state.selectedDocter.doc);
  const SelectedDoctor = useSelector(
    (state: any) => state.curentAppointment.bookAppointmentDoctor.doctor
  );
  const SelectedDoctorUserId = useSelector(
    (state: any) => state.curentAppointment.bookAppointmentDoctor.user_id
  );
  const selectedCompanyEmpID = useSelector(
    (state: any) =>
      state.curentAppointment.bookAppointmentDoctor.companyEmployeeId
  );
  const AppointmentData = useSelector((state: any) => state.curentAppointment);
  const whichVersion = useSelector(
    (state: any) => state.curentAppointment.whichVersion
  );
  const [isLoad, setLoad] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentClick, setPaymentClick] = useState(false);

  const currentAppointmentInfo = useSelector(
    (state: any) => state.curentAppointment
  );

  const App_Type = useSelector(
    (state: any) => state.curentAppointment.appointmentRequestType
  );

  const user = useSelector((state: any) => state.userReducer.user);
  const paymentProvider = useSelector(
    (state: any) => state.curentAppointment.paymentProvider
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [DoctorDetail, setDoctorDetail] = useState({} as any);
  const [objectForSchedulePrivatePay, setObjectForSchedulePrivatePay] = useState({} as any);
  const [formValues, setFormValues] = useState({} as any);
  const [familyMemberDetails, setFamilyMemberDetails] = useState({} as any);
  const [selectedTime, setTime] = useState("");
  const [term, setTerm] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [selectedDate, setDate] = useState("");
  const [dateAndTime, setDateAndTime] = useState("");
  const [chooseServices, setChooseServices] = useState(false);
  const [hideAlert, setHideAlert] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const appointmentTypeNew = useSelector((state: any) => state);
  const [services, setServices] = useState([] as any);
  const [selectedData, setSelectedData] = useState({} as any);
  const [selectedServiceByUser, setSeletedService] = useState({} as any);
  const [comment, setComment] = useState("");
  const [loader, setLoader] = useState(false);
  const [userAgreementModal, setUserAgreementModal] = useState(false);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    console.log(event);
    const name = event.target.name;
    const value = event.target.value;
    console.log(name, value);
    setFormValues((formValues) => ({ ...formValues, [name]: value }));
    setPaymentMethod(event.target.value);
    setErrMsg(false);
  };

  const [userAgent, setUserAgent] = useState('');

  useEffect(() => {
    const userAgent = navigator.userAgent;
    console.log('YSYSYSYSYSY',userAgent);
    setUserAgent(userAgent);
    }, []);
    
  // function handleChangeService(value) {
  //   console.log("wejkwgiyg", value);
  //   selectedData.serviceId = value;
  //   const data = services.filter((item) => item.id == value);
  //   console.log("jhsdvksjhvsfkjhasvfkasjh", data[0]);
  //   currentAppointmentInfo.paymentProvider == "OHIP"
  //     ? (selectedData.serviceCost = "0")
  //     : (selectedData.serviceCost = data[0].servicecost);
  //   setChooseServices(true);
  //   setSeletedService(data[0]);
  //   dispatch(selectedService(selectedData));
  // }

  // const handleChangeTerm = (event) => {
  //   console.log(event.target.value);
  //   setTerm(event.target.value);
  // };

  const logEvents = async (data) => {
    console.log(data)
    try {
      let result = await UPDATEDPOST(`log`, data)
    } catch(e) {
      console.log(e, "ERRORRR")
    }
  }

  const getServices = async () => {

   
    
    const doctorServices = await UPDATEDGET(`services?id=${selectedCompanyEmpID}`);
    console.log("getAllServices___",doctorServices);
    const GetAll = 	doctorServices?.data?.getAllServices || doctorServices?.data?.body || []

    let rawLogs = { 
      "userIdentity": {
        "loggedinuser": `${user?.Email}`,
        "role":`${user?.role}`,
        "createdate":new Date().toISOString()
        },
        "action": "ACCESS",
        "source": "Checkout Page",
        "sourceIPAddress": "3.99.184.26",
        "userAgent": userAgent,
        "ipAddress": localStorage.getItem('ipAddress'),
        "apiendpoint": `services?id=${selectedCompanyEmpID}`,
        "requestParameters": {
          
        },
        "responsestatus": `${doctorServices?.status}`,
        // "response": JSON.stringify(response?.data)
    }

    let logBody = {
      // message: `User - ${user?.sub} - Fetch Appointment - Checkout Page`,
      message: rawLogs,
      logGroupName: "ONRx-Patient-Portal",
      logStreamName: `User - ${user?.sub} - Checkout Page`,
    }

    logEvents(logBody);

    console.log(GetAll, "-*-*-*new api doctorServices");
    if (GetAll?.statusCode == 200) {
      setServices(GetAll);
    }
  };

  const dateAndtimeClick2 = () => {
    const date = currentAppointmentInfo.selectedService.selectedDate;
    const time = currentAppointmentInfo.selectedService.selectedTime;
    const new1 = moment(date).format("YYYY-MM-DD");

    console.log(new1, "new11");

    const t = moment(time, "HH:mm").format("HH:mm");

    const tempBuff = `${new1} ${t}`;

    console.log(tempBuff, "tmpBufff");

    const passDate = moment(tempBuff, "YYYY-MM-DDTHH:mm").format(
      "YYYY-MM-DD HH:mm"
    );

    setDateAndTime(passDate);
  };

  const goToBack = () => {
    history.goBack();
  };

  useEffect(() => {
    paymentInit()
    getPatientDetails();
    console.log(currentAppointmentInfo, "******currentAppointmentInfo");
    if (
      appointmentTypeNew.curentAppointment.appointmentType == "callback" ||
      whichVersion == "without_problem"
    ) {
      // selectedData.serviceId = null;
      // selectedData.serviceCost = 0;
      // selectedData.selectedDate =
      //   currentAppointmentInfo?.selectedService?.selectedDate;
      // selectedData.selectedTime =
      //   currentAppointmentInfo?.selectedService?.selectedTime;
      // dispatch(selectedService(selectedData));
    }

    dateAndtimeClick2();
    setPaymentMethod("Health Card");
    setDoctorDetail(SelectedDoctor);
    getServices();

    if (App_Type !== "familyMember") {
      setFormValues((formValues) => ({
        ...formValues,
        firstname: user.FirstName,
      }));
      setFormValues((formValues) => ({
        ...formValues,
        lastname: user.LastName,
      }));
      setFormValues((formValues) => ({ ...formValues, email: user.Email }));
      setFormValues((formValues) => ({
        ...formValues,
        phone: user.phoneNumber,
      }));
      setFormValues((formValues) => ({
        ...formValues,
        paymentType: "Health Card",
      }));
      setFormValues((formValues) => ({
        ...formValues,
        insurance_number: user.Insurancesvcnum,
      }));
      setFormValues((formValues) => ({
        ...formValues,
        insurance_provider: user.insuranceProvider,
      }));
      setFormValues((formValues) => ({
        ...formValues,
        insurance_version_code: user.insuranceVersionCode,
      }));
    } else {
      var s =
        // currentAppointmentInfo?.familyMember?.userName.split(/(?<=^\S+)\s/);
        currentAppointmentInfo?.familyMember?.userName.split(/[ ,]+/);
      console.log(s);
      if (s) {
        setFormValues((formValues) => ({ ...formValues, firstname: s[0] }));
        setFormValues((formValues) => ({ ...formValues, lastname: s[1] }));
      }

      setFormValues((formValues) => ({
        ...formValues,
        email: familyMemberDetails?.email,
      }));
      setFormValues((formValues) => ({
        ...formValues,
        phone: familyMemberDetails?.phoneNumber,
      }));
    }

    setTime(currentAppointmentInfo.selectedService.selectedTime);
    setDate(currentAppointmentInfo.selectedService.selectedDate);
  }, []);

  const paymentInit = () => {
    setLoad(true);
  }

  useEffect(() => {

    if (App_Type !== "familyMember") {
      setFormValues((formValues) => ({
        ...formValues,
        firstname: user.FirstName,
      }));
      setFormValues((formValues) => ({
        ...formValues,
        lastname: user.LastName,
      }));
      setFormValues((formValues) => ({ ...formValues, email: user.Email }));
      setFormValues((formValues) => ({
        ...formValues,
        phone: user.phoneNumber,
      }));
      setFormValues((formValues) => ({
        ...formValues,
        paymentType: "Health Card",
      }));
      setFormValues((formValues) => ({
        ...formValues,
        insurance_number: user.Insurancesvcnum,
      }));
      setFormValues((formValues) => ({
        ...formValues,
        insurance_provider: user.insuranceProvider,
      }));
      setFormValues((formValues) => ({
        ...formValues,
        insurance_version_code: user.insuranceVersionCode,
      }));
    } else {
      var s =
        // currentAppointmentInfo?.familyMember?.userName.split(/(?<=^\S+)\s/);
        currentAppointmentInfo?.familyMember?.userName.split(/[ ,]+/);
      console.log(s);
      setFormValues((formValues) => ({ ...formValues, firstname: s[0] }));
      setFormValues((formValues) => ({ ...formValues, lastname: s[1] }));
      setFormValues((formValues) => ({
        ...formValues,
        email: familyMemberDetails?.email,
      }));
      setFormValues((formValues) => ({
        ...formValues,
        phone: familyMemberDetails?.phoneNumber,
      }));
    }
  }, [familyMemberDetails]);

  const getPatientDetails = async () => {
    if (currentAppointmentInfo?.familyMember?.familymemberid) {

    

      try {
        const data = await UPDATEDGET(
          `user/profile/${currentAppointmentInfo?.familyMember?.familymemberid}`
        );


        let rawLogs = {
          "userIdentity": {
          "loggedinuser": `${user?.Email}`,
          "role":`${user?.role}`,
          "createdate":new Date().toISOString()
          },
          "action": "ACCESS",
          "source": "Checkout Page",
          "sourceIPAddress": "3.99.184.26",
          "userAgent": userAgent,
          "ipAddress": localStorage.getItem('ipAddress'),
          "apiendpoint": `user/profile/${currentAppointmentInfo?.familyMember?.familymemberid}`,
          "requestParameters": {
            
          },
          "responsestatus": `${data?.status}`,
          // "response": JSON.stringify(response?.data)
        }
  
        let logBody = {
          // message: `User - ${user?.sub} - Fetch Family Member Info - Checkout Page`,
          message: rawLogs,
          logGroupName: "ONRx-Patient-Portal",
          logStreamName: `User - ${user?.sub} - Checkout Page`,
        }
    
        logEvents(logBody);

        console.log(data.data[0][0], "Family data");
        setFamilyMemberDetails(data.data[0][0]);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const squarePayment = isLoad ? (
    <PaymentForm
      /**
       * Identifies the calling form with a verified application ID generated from
       * the Square Application Dashboard.
       */
      // applicationId="sandbox-sq0idb-xsjhqKMUXbE4V1kC7STkFw"
      // locationId="L4ZD3QVYFXK93"

      applicationId={config?.applicationid}
      locationId={config?.locationid}
      
      /**
        * Invoked when payment form receives the result of a tokenize generation
        * request. The result will be a valid credit card or wallet token, or an error.
        */
      cardTokenizeResponseReceived={(token, buyer) => {
        console.log("ahiii token",token?.token, config?.paymentUrl, "====????");

        const paymentUrl = config?.paymentUrl;

        if(token?.token){
          setPaymentClick(true);
          // fetch(process.env.REACT_APP_PAYMENT_API, {
          fetch(paymentUrl, {
            method: 'POST',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            },
            body: JSON.stringify({
            nonce: token?.token,
            amount : AppointmentData?.selectedService?.serviceCost * 100
            })
          })
          .catch(err => {
            setPaymentClick(false);
            alert('Network error: ' + err);
          })
          .then((response : any) => {
              if (!response.ok) {
              return response.text().then(errorInfo => Promise.reject(errorInfo));
              }
              return response.text();
          })
          .then((data : any) => {
              console.log(JSON.parse(data),"result of payment"); 
              requestScheduleAppointment(JSON.parse(data)?.result?.payment);
          })
          .catch(err => {
              console.error(err);
              setPaymentClick(false);
              notification.error({
                message: "'Payment failed to complete!",
              });
          });
        }
      }}
      /**
       * This function enable the Strong Customer Authentication (SCA) flow
       *
       * We strongly recommend use this function to verify the buyer and reduce
       * the chance of fraudulent transactions.
       */
      createVerificationDetails={() => ({
        /* omit amount key if you want to store the card details */
        amount: AppointmentData?.selectedService?.serviceCost+'',
        /* collected from the buyer */
        billingContact: {
          addressLines: [user?.Address1],
          familyName: user?.FirstName + " " + user?.LastName,
          givenName: user?.FirstName + " " + user?.LastName,
          countryCode: 'CA',
          city: user?.City,
        },
        currencyCode: 'CAD',
        intent: 'CHARGE',
      })}
    >
      <div className="form-group">
          <label htmlFor="nameOnCard">Name on card</label>
          <input type="text" className="form-control" style={{fontWeight: 400,fontSize: 16}} id="nameOnCard" name="nameOnCard" />
      </div>

      <CreditCard
        postalCode="false"
        callbacks={{
          submit(event) {
            console.info("submit: ", event);
          }
        }}
        focus="cardNumber"
        buttonProps={{
          isLoading: paymentClick,
        }}
        onClick={() => {
          console.log("payment button clicked");
        }}
      >
        Submit Payment
      </CreditCard>
    </PaymentForm>
  ) : null;

  const requestScheduleAppointment = (paymentId) => {
    let data = objectForSchedulePrivatePay;
    data = { ...data, paymentid: paymentId.id, recept_number : paymentId.receipt_number };
    console.log("requestedDataforSchhedule: ", data);
    
    fetch(config?.BASEURL + 'appointment', {
        method: 'POST',
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        // "Authorization": store.get('auth_token_refresh')
        "Authorization": `Bearer ${store.get("auth_token_refresh")}`
        },
        body: JSON.stringify(data)
      })
      .catch(err => {
          alert('Network error: ' + err);
      })
      .then((response: any) => {
          if (!response.ok) {
          return response.text().then(errorInfo => Promise.reject(errorInfo));
          }
          return response.text();
      })
      .then(data => {
        console.log(data,"apointment created");
        setPaymentClick(false);
        localStorage.removeItem("newDate");
        notification.success({
          message: "Payment complete successfully!",
        });

        history.push('/dashboard');
    })
    .catch(err => {
      setPaymentClick(false);
      console.error(err);
    });
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      formValues.firstname !== "" &&
      formValues.formValues !== ""
    ) {
      if (term === true) {

        if (appointmentTypeNew?.curentAppointment?.paymentProvider === 'PRIVATE_PAY') {
          // open Payment Modal

          let objectForSchedulePrivate = {
            appointmenttype: formValues.appointmentType || "Virtual",
            bookedby: user.id,
            createdate: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
            createdby: user.id,
            detail: comment ? comment : "NA",
            enddatetime: moment(dateAndTime)
              // .add(selectedServiceByUser?.serviceduration, "minutes")
              .add(AppointmentData?.selectedService?.serviceduration, "minutes")
              .format("YYYY-MM-DD HH:mm:ss"),
            inviteemail: DoctorDetail.Email,
            isfamilymember: 0,
            parentpatientemail: user?.Email,
            patientid:
              App_Type !== "familyMember"
                ? user.id
                : currentAppointmentInfo?.familyMember?.familymemberid,
            providerid: selectedCompanyEmpID,
            service: AppointmentData?.selectedService?.serviceId,
            source: "Patientportal",
            startdatetime: dateAndTime,
            status: "DRAFT",
            vitalsnote: currentAppointmentInfo.selectedService.vitalTextValue,
            paymenttype:
              appointmentTypeNew?.curentAppointment?.paymentProvider || "null",
            requesttype: "SCHEDULED",
            timezone: moment().format("Z")
          }

          setObjectForSchedulePrivatePay(objectForSchedulePrivate)
          setPaymentModal(true);
        } else {
          let data = {};
          console.log(formValues,"formValuesformValues")
          data = {
            appointmenttype: formValues.appointmentType || "Virtual",
            bookedby: user.id,
            createdate: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
            createdby: user.id,
            detail: comment ? comment : "NA",
            enddatetime: moment(dateAndTime)
              // .add(selectedServiceByUser?.serviceduration, "minutes")
              .add(AppointmentData?.selectedService?.serviceduration, "minutes")
              .format("YYYY-MM-DD HH:mm:ss"),
            inviteemail: DoctorDetail.Email,
            isfamilymember: 0,
            parentpatientemail: user?.Email,
            patientid:
              App_Type !== "familyMember"
                ? user.id
                : currentAppointmentInfo?.familyMember?.familymemberid,
            providerid: selectedCompanyEmpID,
            service: AppointmentData?.selectedService?.serviceId,
            source: "Patientportal",
            startdatetime: dateAndTime,
            status: "DRAFT",
            vitalsnote: currentAppointmentInfo.selectedService.vitalTextValue,
            paymenttype:
              appointmentTypeNew?.curentAppointment?.paymentProvider || "null",
            requesttype: "SCHEDULED",
            timezone: moment().format("Z"),
          };
          if (appointmentTypeNew?.curentAppointment?.paymentProvider === "OHIP") {
            data = { ...data, paymentid: currentAppointmentInfo?.Insurance.id };
          }

          console.log(data, "data data data checkout")

         

          setLoader(true);
          const statistics = await UPDATEDPOST(`appointment`, data);
          console.log(statistics.data, "statistics.data");

          let rawLogs = {
            "userIdentity": {
            "loggedinuser": `${user?.Email}`,
            "role":`${user?.role}`,
            "createdate":new Date().toISOString()
            },
            "action": "INSERT",
            "source": "Checkout Page",
            "sourceIPAddress": "3.99.184.26",
            "userAgent": userAgent,
            "ipAddress": localStorage.getItem('ipAddress'),
            "apiendpoint": `appointment`,
            "requestParameters": {
              data
            },
            "responsestatus": `${statistics?.status}`,
            // "response": JSON.stringify(response?.data)
        }

        let logBody = {
          // message: `User - ${user?.sub} - Create Appointment - Checkout Page`,
          message: rawLogs,
          logGroupName: "ONRx-Patient-Portal",
          logStreamName: `User - ${user?.sub} - Checkout Page`,
        }
    
        logEvents(logBody);
          localStorage.removeItem("newDate");
          setLoader(false);
          history.push("/dashboard");
          notification.success({
            message: "",
            duration: 2,
            description: "Appointment Requested Succesfully",
            onClick: () => {
              console.log("Notification Clicked!");
            },
          });
          dispatch(selectedService({}));
          dispatch(selectAppointmentType({}));
          dispatch(selectPaymentProvider({}));
        }
      } else {
        notification.error({
          message: "Error",
          duration: 2,
          description: "Please accept Terms & Conditions to continue.",
          onClick: () => {
            console.log("Notification Clicked!");
          },
        });
      }
    } else {
      setErrMsg(true);
      notification.error({
        message: "Error",
        duration: 2,
        description: "Please Enter all the mandatory fields .",
      });
    }
    const consentData = {
      added_by: user?.id,
      consent_page: "CheckOut",
      consent_name: "Terms & Conditions",
      timestemp: moment().format('YYYY-MM-DD HH:mm:ss')
    };

    const Consent = await UPDATEDPOST("consent", consentData);

    console.log(Consent, "Consent");
  };

  const handleChangeComment = (event) => {
    setComment(event.target.value);
  };
  const termsAndConditions = (event: any) => {
    console.log(event.target.checked);
    setTerm(event.target.checked);
  };

  const cancelBack = () => {
    setHideAlert(true);
  };
  const handleBack = () => {
    setHideAlert(false);
    history.push("/dashboard");
  };
  const onCancel = () => {
    setHideAlert(false);
  };

  const getUserImage = (imageUrl) => {
    if (
      imageUrl?.match(/^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp)(\?(.*))?$/gim) !=
      null
    ) {
      return imageUrl;
    }
    return `${process.env.REACT_APP_ASSET_URL}/${imageUrl}`;
  };

  return (
    <div>
      <TopBar isBookAppointment={true} />
      <div>
        <div>
          <div className="content">
            <div className="container-fluid">
            <PageHeader
            items={[
              { label: "Dashboard", link: "/dashboard" },
              { label: "Checkout", link: "" },
            ]}
            pageTitle="Checkout"
          />
          <div className="container">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-xl-12">
                  <div className="stepDiv d-none d-sm-block">
                    {appointmentTypeNew.curentAppointment.appointmentType ==
                      "callback" ? (
                      <Steps size="small">
                        <Step status="finish" title="Payment" />
                        {/* <Step status="finish" title="Problems" /> */}
                        <Step status="finish" title="Select Type" />
                        <Step status="finish" title="Select Slot" />
                        <Step status="finish" title="Select Doctor" />
                        <Step status="finish" title="Book Appointment" />
                      </Steps>
                    ) : (
                      <Steps size="small">
                        <Step status="finish" title="Select Type" />
                        <Step status="finish" title="Select Doctor" />
                        {/* <Step status="finish" title="Payment" /> */}
                        {whichVersion == "with_problem" ? (
                          <Step status="finish" title="Problems" />
                        ) : null}
                        <Step status="finish" title="Select Slot" />
                        <Step status="finish" title="Book Appointment" />
                      </Steps>
                    )}
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <div className={`col-md-7 col-lg-8 ${style.div_form}`}>
                  <div className="card">
                    <div className="card-body">
                      <form onSubmit={handleSubmit}>
                        <div className="info-widget pb-4">
                          <h4 className="card-title">Personal Information</h4>
                          <div className="row mb-0">
                            <div className="col-md-6 col-sm-12">
                              <div className="form-group card-label">
                                <label>First Name</label>
                                <input
                                  disabled={true}
                                  type="text"
                                  className="form-control"
                                  name="firstname"
                                  value={formValues.firstname || ""}
                                  onChange={handleChange}
                                  style={{ cursor: "not-allowed", backgroundColor: "#FAFAFA", color: "lightgray" }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                              <div className="form-group card-label">
                                <label>Last Name</label>
                                <input
                                  disabled={true}
                                  type="text"
                                  className="form-control"
                                  name="lastname"
                                  value={formValues.lastname || ""}
                                  onChange={handleChange}
                                  style={{ cursor: "not-allowed", backgroundColor: "#FAFAFA", color: "lightgray" }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                              <div className="form-group card-label">
                                <label>Email</label>
                                <input
                                  disabled={
                                    App_Type != "familyMember" ? true : false
                                  }
                                  className="form-control"
                                  type="email"
                                  name="email"
                                  value={formValues.email || ""}
                                  onChange={handleChange}
                                  style={{
                                    cursor:
                                      App_Type == "familyMember"
                                        ? "default"
                                        : "not-allowed",
                                    backgroundColor: App_Type == "familyMember" ? "#ffffff" : "#FAFAFA",
                                    color: App_Type == "familyMember" ? "#000000" : "lightgray",
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                              <div className="form-group card-label" style={{ marginBottom: "5px" }}>
                                <label>Phone</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="phone"
                                  value={formValues.phone || ""}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                          {/* <div className="exist-customer">
                            Existing Customer?
                            <Link to="/patient/checkout">
                              {" "}
                              Click here to login
                            </Link>
                          </div> */}
                        </div>

                        {/* {appointmentTypeNew.curentAppointment.appointmentType ==
                          "callback" || whichVersion == "without_problem" ? (
                          <>
                            <h4 className="card-title">Select Service *</h4>

                            <Select
                              showSearch
                              placeholder="Select Service"
                              optionFilterProp="children"
                              onChange={handleChangeService}
                              style={{
                                width: 300,
                                borderWidth: 1,
                                borderColor: "rgba(0,0,0,0.)",
                                borderRadius: 5,
                                marginRight: 15,
                                marginBottom: 25,
                              }}
                            >
                              {services?.map((itemm, index) => (
                                <>
                                  {itemm.serviceName != null ? (
                                    <Option key={index} value={itemm.id}>
                                      {itemm.serviceName}
                                    </Option>
                                  ) : null}
                                </>
                              ))}
                            </Select>
                            <div className={`${style.divLine}`} />
                          </>
                        ) : null} */}

                        <h4 className="card-title">Appointment Type</h4>
                        <div className="payment-list">
                          <div className={style.paymentlist}>
                            <label className="payment-radio credit-card-option">
                              <input
                                type="radio"
                                name="appointmentType"
                                defaultChecked
                                value="Virtual"
                                onChange={handleChange}
                              />
                              <span className="checkmark"></span>
                              Virtual
                            </label>
                            <label className="payment-radio credit-card-option">
                              <input
                                type="radio"
                                name="appointmentType"
                                value="In-person"
                                onChange={handleChange}
                              />
                              <span className="checkmark"></span>
                              In-person
                            </label>
                          </div>
                        </div>

                        <h4 className="card-title">Problem Description</h4>

                        <div className="form-group card-label">
                          <textarea
                            onChange={handleChangeComment}
                            className="form-control"
                          />
                        </div>

                        <div className={`${style.divLine}`} />
                        {/* <h4 className="card-title">Payment Method</h4> */}
                        <div className="payment-widget info-widget p-0 m-0">
                          {/* <div className="payment-list">
                            <div className={style.paymentlist}>
                              <label className="payment-radio credit-card-option">
                                <input
                                  type="radio"
                                  name="paymentType"
                                  value="Health Card"
                                  checked={
                                    formValues.paymentType == "Health Card"
                                  }
                                  onChange={handleChange}
                                />
                                <span className="checkmark"></span>
                                Health Card
                              </label>
                              <label className="payment-radio credit-card-option">
                                <input
                                  type="radio"
                                  name="paymentType"
                                  value="Private Insurance"
                                  checked={
                                    formValues.paymentType ==
                                    "Private Insurance"
                                  }
                                  onChange={handleChange}
                                />
                                <span className="checkmark"></span>
                                Private Insurance
                              </label>

                              <label className="payment-radio credit-card-option">
                                <input
                                  type="radio"
                                  name="paymentType"
                                  value="Private Pay"
                                  checked={
                                    formValues.paymentType == "Private Pay"
                                  }
                                  onChange={handleChange}
                                />
                                <span className="checkmark"></span>
                                Private Pay
                              </label>
                            </div>
                          </div> */}

                          {paymentMethod == "Private Pay" ? (
                            <div>
                              <div className="payment-list">
                                <label className="payment-radio credit-card-option">
                                  <input
                                    type="radio"
                                    name="radio"
                                    defaultChecked
                                  />
                                  <span className="checkmark"></span>
                                  Credit card
                                </label>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group card-label">
                                      <label htmlFor="card_name">
                                        Name on Card
                                      </label>
                                      <input
                                        className="form-control"
                                        id="card_name"
                                        type="text"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group card-label">
                                      <label htmlFor="card_number">
                                        Card Number
                                      </label>
                                      <input
                                        className="form-control"
                                        id="card_number"
                                        placeholder="1234  5678  9876  5432"
                                        type="text"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="form-group card-label">
                                      <label htmlFor="expiry_month">
                                        Expiry Month
                                      </label>
                                      <input
                                        className="form-control"
                                        id="expiry_month"
                                        placeholder="MM"
                                        type="text"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="form-group card-label">
                                      <label htmlFor="expiry_year">
                                        Expiry Year
                                      </label>
                                      <input
                                        className="form-control"
                                        id="expiry_year"
                                        placeholder="YY"
                                        type="text"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="form-group card-label">
                                      <label htmlFor="cvv">CVV</label>
                                      <input
                                        className="form-control"
                                        id="cvv"
                                        type="text"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="payment-list">
                                <label className="payment-radio paypal-option">
                                  <input type="radio" name="radio" />
                                  <span className="checkmark"></span>
                                  Wallet
                                </label>
                              </div>
                            </div>
                          ) : null}

                          {/* {paymentMethod == "Health Card" || paymentMethod == "Private Insurance" ?
                            <label htmlFor="card_name" style={{ marginBottom: 15 }}>
                              Insurance
                            </label>
                            : null} */}

                          {/* {paymentMethod == "Health Card" ? (
                            <div className="payment-list">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group card-label">
                                    <label htmlFor="card_name">
                                      Insurance Number
                                    </label>
                                    <input
                                      value={formValues.insurance_number || ""}
                                      onChange={handleChange}
                                      name ="insurance_number"
                                      className="form-control"
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group card-label">
                                    <label htmlFor="card_number">
                                      Insurance Provider
                                    </label>
                                    <input
                                      value={formValues.insurance_provider || ""}
                                      onChange={handleChange}
                                      name ="insurance_provider"
                                      className="form-control"
                                      placeholder=""
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group card-label">
                                    <label htmlFor="card_number">
                                      Insurance Version Code
                                    </label>
                                    <input
                                      value={formValues.insurance_version_code || ""}
                                      onChange={handleChange}
                                      name ="insurance_version_code"
                                      className="form-control"
                                      placeholder=""
                                      type="text"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {paymentMethod == "Private Insurance" ? (
                            <div className="payment-list">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group card-label">
                                    <label htmlFor="card_name">
                                      Insurance Number
                                    </label>
                                    <input
                                      value={formValues.insurance_number || ""}
                                      onChange={handleChange}
                                      name ="insurance_number"
                                      className="form-control"
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group card-label">
                                    <label htmlFor="card_number">
                                      Insurance Provider
                                    </label>
                                    <input
                                      value={formValues.insurance_provider || ""}
                                      onChange={handleChange}
                                      name ="insurance_provider"
                                      className="form-control"
                                      placeholder=""
                                      type="text"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group card-label">
                                    <label htmlFor="card_number">
                                      Insurance Version Code
                                    </label>
                                    <input
                                      value={formValues.insurance_version_code || ""}
                                      onChange={handleChange}
                                      name="insurance_version_code"
                                      className="form-control"
                                      placeholder=""
                                      type="text"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null} */}

                          <div className="terms-accept">
                            <div>
                              <Checkbox
                                onChange={termsAndConditions}
                                style={{ marginTop: 4 }}
                              >
                                I have read and accept{" "}
                              </Checkbox>
                              <span>
                                <a
                                  target="_blank"
                                  href="https://onhealthalliance.com/terms-of-use/"
                                  // onClick={() => setUserAgreementModal(true)}
                                  className="pt-1"
                                >
                                  Terms &amp; Conditions
                                </a>
                              </span>
                            </div>
                          </div>
                          {/* <div className="terms-accept">
                            <div className="custom-checkbox d-flex">
                              <input
                                type="checkbox"
                                onChange={termsAndConditions}
                                name="terms_accept"
                                style={{ width: 15, marginTop: 4 }}
                              />
                              <label htmlFor="terms_accept">
                                I have read and accept{" "}
                                <a href="/term-and-conditions">
                                  Terms &amp; Conditions
                                </a>
                              </label>
                            </div>
                          </div> */}
                        </div>
                        <div
                          className="submit-section mt-4 row"
                          style={{ marginTop: 20, marginLeft: 6 }}
                        >
                          <div className={style.btnContainer}>
                            <div className={style.btnBox1}>
                              <button
                                type="button"
                                className={`${style.btnBg1} mr-3`}
                                onClick={() => goToBack()}
                              >
                                Previous
                              </button>
                              {loader ? <Spin /> :
                                <button
                                  type="submit"
                                  className={`btn btn-primary ${style.submitBtn}`}
                                  disabled={!term}
                                >
                                  Schedule
                                </button>
                              }
                            </div>
                            <div className={style.btnBox2}>
                              <button
                                type="button"
                                className={`${style.btnBg2} mx-3`}
                                onClick={() => cancelBack()}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                <div className="col-md-5 col-lg-4 theiaStickySidebar">
                  <div className="card booking-card">
                    <div className="card-header">
                      <h4 className="card-title">Booking Summary</h4>
                    </div>
                    <div className="card-body">
                      <div className="booking-doc-info pb-3">
                        <span className="booking-doc-img">
                          {DoctorDetail?.profilepicture != "" &&
                            DoctorDetail?.profilepicture != null ? (
                            <img
                              // src={DoctorDetail?.profilepicture}
                              src={getUserImage(DoctorDetail?.profilepicture)}
                              onError={(e: any) => {
                                e.target.onerror = null;
                                e.target.src =
                                  {IMG01};
                              }}
                              width="100"
                              height="100"
                              alt=""
                              className="img-fluid"
                            />
                          ) : (
                            <img
                              src={IMG01}
                              className="img-fluid"
                              alt="User"
                            />
                          )}
                        </span>
                        <div className="booking-info">
                          <h4 style={{ marginBottom: "5px", marginTop: "5px" }}>
                            {/* <Link to="/patient/doctor-profile"> */}
                            Dr. {DoctorDetail?.FirstName}{" "}
                            {DoctorDetail?.LastName}
                            {/* </Link> */}
                          </h4>
                          <div className="rating">
                            <Rate disabled />
                            <span className="d-inline-block average-rating"></span>
                          </div>
                          <div className="clinic-details">
                            {DoctorDetail?.Address1 ? (
                              <p className="doc-location">
                                <FontAwesomeIcon icon={faMapMarker} />{" "}
                                {DoctorDetail?.Address1}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="booking-summary">
                        <div className="booking-item-wrap">
                          <ul className="booking-date">
                            <li>
                              Date{" "}
                              <span>
                                {moment(
                                  currentAppointmentInfo.selectedService
                                    ?.selectedDate
                                ).format("DD-MMM-YYYY")}
                              </span>
                            </li>
                            {appointmentTypeNew.curentAppointment
                              .appointmentType == "callback" ? (
                              <li>
                                Time{" "}
                                <span>
                                  {
                                    appointmentTypeNew.curentAppointment
                                      .callback
                                  }
                                </span>
                              </li>
                            ) : (
                              <li>
                                Time{" "}
                                <span>
                                  {moment(selectedTime, "hh:mma").format(
                                    "hh:mma"
                                  )}
                                </span>
                              </li>
                            )}
                          </ul>
                          <ul className="booking-fee">
                            {/* <li>
                              Consulting Fee <span>--</span>
                            </li> */}
                            {/* {appointmentTypeNew.curentAppointment.appointmentType == 'callback' ?  */}
                            <li>
                              {/* Booking Fee <span>$ 0</span>
                              </li> : 
                              <li> */}
                              Booking Fee{" "}
                              <span>
                                $
                                {
                                  currentAppointmentInfo.selectedService
                                    .serviceCost
                                }
                              </span>
                            </li>
                            {/* } */}

                            {currentAppointmentInfo?.selectedService?.name !==
                              undefined ? (
                              <li>
                                Services
                                <span>
                                  {currentAppointmentInfo.selectedService.name}
                                </span>
                              </li>
                            ) : null}

                            <li>
                              Payment Provider{" "}
                              <span>
                                {currentAppointmentInfo.paymentProvider}
                              </span>
                            </li>
                            {/* <li>
                              Video Call <span>--</span>
                            </li> */}
                          </ul>
                          <div className="booking-total">
                            <ul className="booking-total-list">
                              <li>
                                <span>Total</span>

                                {/* {appointmentTypeNew.curentAppointment.appointmentType == 'callback' ?  */}
                                {/* // <span className="total-cost"> $ 0</span> : */}
                                <span className="total-cost">
                                  {" "}
                                  $
                                  {
                                    currentAppointmentInfo.selectedService
                                      .serviceCost
                                  }
                                </span>
                                {/* } */}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
            
          </div>
        </div>
      </div>

      <Modal
        title="Payment"
        centered
        visible={paymentModal}
        onOk={() => setPaymentModal(false)}
        onCancel={() => setPaymentModal(false)}
        footer={null}
      >
        {squarePayment}
      </Modal>

      <Modal
        centered
        visible={userAgreementModal}
        width={1000}
        onOk={() => setUserAgreementModal(false)}
        onCancel={() => setUserAgreementModal(false)}
        footer={null}
      >
        <div className="pt-4">
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
            <Viewer
              fileUrl='https://cf2c9230-6450-4364-9252-7d656ea6986d.filesusr.com/ugd/911847_05eee2ae00b84bd9a94262e36b211932.pdf'
              plugins={[
                defaultLayoutPluginInstance,
              ]}
            />
          </Worker>
        </div>
      </Modal>

      {hideAlert && (
        <SweetAlert
          warning
          title="Are you sure?"
          onConfirm={handleBack}
          onCancel={onCancel}
          showCancel={true}
          // focusCancelBtn={true}
          // confirmBtnBsStyle="danger"
          confirmBtnText="Yes"
          cancelBtnText="No"
        />
      )}

      <div className={style.footer}>{/* <Footer /> */}</div>
    </div>
  );
};

export default Checkout;

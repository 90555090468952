import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleRight,
  faCheck,
  faCheckCircle,
  faCoffee,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import style from "./style.module.css";
import { Button } from "antd/lib/radio";
import { notification, Modal } from "antd";
import SweetAlert from "react-bootstrap-sweetalert";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAppointmentType,
  selectedService,
  selectPaymentProvider,
} from "redux/actions/userActions";
import {
  setStartTimeBasedOnCallBackRequest,
  setStartTimeBasedOnCallBackRequestWithDate,
  setStartTimeBasedOnCallBackRequestWithDateAndTime,
} from "../../utils/common";
import { UPDATEDPOST } from "../../services/common.api";
import moment from "moment";

const Footer = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const appointmentInfo = useSelector((state: any) => state.curentAppointment);
  const userData = useSelector((state: any) => state.userReducer.user);
  const paymentProvider = useSelector(
    (state: any) => state.curentAppointment.paymentProvider
  );
  const familyMemberState: any = useSelector(
    (state: any) => state?.curentAppointment?.familyMember
  );
  const currentAppointmentState: any = useSelector(
    (state: any) => state?.curentAppointment?.appointmentRequestType
  );
  const appointmentTypeNew = useSelector((state: any) => state);
  const [hideAlert, setHideAlert] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  
  console.log(props, "PROPSSSSPROPSSSSPROPSSSSPROPSSSS")

  console.log(familyMemberState, "familyMemberState______");
  

  const handleSuccess = () => {
    setShowModal(true);
    setTimeout(() => {
      setShowModal(false);
    }, 10000); // Close the modal automatically after 2 seconds
  };

  const cancleback = () => {
    setShowNotification(true);
  };

  const handleback = () => {
    setShowNotification(false);
    history.push("/dashboard");
  };

  const oncancle = () => {
    setShowNotification(false);
  };

  const continuee = () => {
    // setShowModal(true)
    // console.log('showModal click pr aave che',showModal);
    setNextButtonDisabled(true);
    if (props.pageName == "selectType") {
      history.push(`/${props.location}`);
    } else if (props.pageName == "select-callback") {
      if (props.value != "") {
        history.push(`/${props.location}`);
      } else {
        notification.error({
          message: "Error",
          duration: 2,
          description: "Please Select Problem.",
          onClick: () => {
            console.log("Notification Clicked!");
          },
        });
      }
    } else if (props.pageName == "problems") {
      if (props.value != "") {
        // localStorage.setItem("problem_id", props.value);

        dispatch(selectAppointmentType("fastService"));
        history.push(`/${props.location}`);
      } else {
        notification.error({
          message: "Error",
          duration: 2,
          description: "Please Select Problem.",
          onClick: () => {
            console.log("Notification Clicked!");
          },
        });
      }
    } else if (props.pageName == "appoiment-type") {
      history.push(`/${props.location}`);
    } else if (props.pageName == "bookAppointment") {
      console.log(props.services, "SER");
      if (
        props.book != "" &&
        props.services.serviceId != "" &&
        appointmentInfo.selectedService.selectedTime
      ) {
        history.push(`/${props.location}`);
      } else {
        notification.error({
          message: "Error",
          duration: 2,
          description: "Select Appoiment Slot and services.",
          onClick: () => {
            console.log("Notification Clicked!");
          },
        });
      }
    } else if (props.pageName == "Submit") {
      
      if (props.valueReason != "") {
        requestCallBackAndSaveAsAppointment(props.valueReason, props.appTime);
        cancleback();
        // handleSuccess()
        // notification.success({
        //   message: "Callback Requested Succesfully",
        //   duration: 2,
        //   // description: "Callback Requested Succesfully.",
        // });
        // console.log(props.valueReason, "footer")
      } else {
        notification.error({
          message: "Error",
          duration: 2,
          description: "Please Enter Reason.",
        });
      }
    } else if (props.pageName === "selectPrivateService") {
      const selectedData = {
        serviceId: props?.selectedServiceData?.id,
        reason: props?.selectedServiceData?.description,
        selectedDate: moment(props.appoimentDate, "dddd, MMM DD, YYYY").format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        serviceCost: props?.selectedServiceData?.fees,
        serviceduration: 10,
        selectedTime: props.slotsTime,
        selectslot: props.selectslot,
        vitalsnote: props.ValueForVitalText,
        selectedServiceName: props?.selectedServiceData?.service,
      };
      dispatch(selectedService(selectedData));
      history.push(`/${props.location}`);
      console.log(selectedData, "selectedDataselectedDataselectedData_-");
      
    }
  };
  const requestCallBackAndSaveAsAppointment = (reason, time) => {
    console.log(props, "callback request ahiya", time);
    let UserId;
    if (currentAppointmentState == "Own") {
      UserId = userData.id;
    } else {
      UserId = familyMemberState?.familymemberid;
    }

    const currentUtcDateTime = moment.utc();
    const formattedCurrentUtcDateTime = currentUtcDateTime.format("YYYY-MM-DD HH:mm:ss");

    const requestedDataforCallBack = {
      patientid: UserId,
      callbackstatus: "NEW",
      paymenttype: paymentProvider,

      service:
        paymentProvider == "PRIVATE_PAY"
          ? props?.selectedServiceData?.id
          : null,

      // callbacktime: setStartTimeBasedOnCallBackRequestWithDate(appointmentTypeNew.curentAppointment.callback, "start", props.appoimentDate),
      callbacktime: props.slotsTime,
      vitalsnote: props.ValueForVitalText,
      doctorid: null,
      created_at: formattedCurrentUtcDateTime,
      updated_at: moment(props.appoimentDate, "dddd, MMM DD, YYYY").format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      updated_by: userData.id,
      created_by: userData.id,
      callbackreason: reason,
      // callbackreason: "testing1",
      callbackdate: moment(props.appoimentDate, "dddd, MMM DD, YYYY").format(
        "YYYY-MM-DD"
      ),
      comments: "NA",
      paymentid: appointmentInfo?.Insurance?.id,
      callbackstart: moment(
        setStartTimeBasedOnCallBackRequestWithDateAndTime(
          props.slotsTime,
          "start",
          props.appoimentDate
        ),
        "YYYY-MM-DD HH:mm A"
      ).format("YYYY-MM-DD HH:mm A"),
      callbackend: moment(
        setStartTimeBasedOnCallBackRequestWithDateAndTime(
          props.slotsTime,
          "end",
          props.appoimentDate
        ),
        "YYYY-MM-DD HH:mm A"
      ).format("YYYY-MM-DD HH:mm A"),
    };

    console.log(requestedDataforCallBack, "param===>>>", props.appoimentDate);
    console.log(props.slotsTime, "slotsTime");
    console.log('props?.selectedServiceData<<',props?.ValueForVitalText)
    console.log("requestedDataforCallBack: ", requestedDataforCallBack);

    console.log(moment(props.appoimentDate, "dddd, MMM DD, YYYY HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"), "=============", props.appoimentDate)

    UPDATEDPOST("callbackrequests", requestedDataforCallBack).then(async () => {

      const currentUtcDateTime = moment.utc();
      const formattedCurrentUtcDateTime = currentUtcDateTime.format("YYYY-MM-DD HH:mm:ss");

      let obj = {
        userid: userData.id,
        notificationtype: "request",
        details: "Your appointment callback request has been received.",
        createdate: formattedCurrentUtcDateTime,
        status: 0,
      };

      

      console.log("OBNBBHSJSHHSS____",obj);
      

      let result = await UPDATEDPOST("notification", obj);

      console.log(result, "result");

      // notification.success({
      //   message: "Success",
      //   duration: 2,
      //   description: "Callback request created Successfully",
      //   onClick: () => {
      //     console.log("Notification Clicked!");
      //   },
      // });

      dispatch(selectAppointmentType({}));
      dispatch(selectPaymentProvider({}));
      setNextButtonDisabled(false);
      history.push("/dashboard");
    });
  };

  const goBack = () => {
    history.goBack();
  };
  const cancelBack = () => {
    setHideAlert(true);
  };
  const handleBack = () => {
    setHideAlert(false);
    history.push("/dashboard");
  };
  const onCancel = () => {
    setHideAlert(false);
  };

  return (
    <div
      className="text-center py-4 new"
      style={{ backgroundColor: "#ffffff ", zIndex: 200 }}
    >
      <div className="row align-item-center justify-content-center mb-2">
        <Button
          type="primary"
          className="btnBgred"
          onClick={() => cancelBack()}
        >
          Cancel
        </Button>
        {props.hide !== "false" ? (
          <Button
            type="primary"
            className={
              (props.disbleFooter || props.disbleFooter == undefined) &&
              nextButtonDisabled == false
                ? "btnBg2 ml-2"
                : "btnBg2_disabled ml-2"
            }
            onClick={() => continuee()}
          >
            Book Appointment
          </Button>
        ) : // <Button
        //   type="primary"
        //   className={"btnBg2"}
        //   onClick={() => continuee()}
        // >
        //   Next
        // </Button>
        null}
        {/* <div className="col">
       
          <div className={style.div_btn}>
          <Button
              type="primary"
              className="btnBgred"
              onClick={() => cancelBack()}
            >
              Cancell
            </Button>
          </div>
        </div> */}
        {/* <div className="col">
          {props.hide !== "false" ? (
            <Button
              type="primary"
              className={
                (props.disbleFooter || props.disbleFooter == undefined) &&
                nextButtonDisabled == false
                  ? "btnBg2 ml-2"
                  : "btnBg2_disabled ml-2"
              }
              onClick={() => continuee()}
            >
              Book Appointment
            </Button>
          ) :  <Button
           type="primary"
             className={"btnBg2"}
            onClick={() => continuee()}
          >
            Next
          </Button>
          null} */}
          {/* <div className="row">
            <div className={`${style.div_btn} col-sm-12`}>
              <Button type="primary" className="btnBg1" 
              onClick={goBack}>
                Previous
              </Button>
              {props.hide !== "false" ? (
                <Button
                  type="primary"
                  className={
                    (props.disbleFooter || props.disbleFooter == undefined) && nextButtonDisabled == false
                      ? "btnBg2 ml-2"
                      : "btnBg2_disabled ml-2"
                  }
                  onClick={() => continuee()}
                >
                  Book Appointment
                </Button>
              ) :  <Button
                 type="primary"
                 className={"btnBg2"}
               onClick={() => continuee()}
             >
               Next
             </Button>
                null}
            </div>
          </div> */}
        {/* </div> */}
      </div>

      {/* <Modal
        visible={showModal}
        centered
        footer={null}
        onCancel={() => setShowModal(false)}
        bodyStyle={{ textAlign: "center" }}
        >
         <FontAwesomeIcon className={`mr-3 ${style.iconCal}`} style={{ color: "#5cb85c", fontSize: "50px" }}  icon={faCheckCircle} />
        <h2>Callback request created successfully</h2>
      </Modal> */}

      {showNotification && (
        <SweetAlert
          success
          title="Callback request created successfully"
          onConfirm={handleback}
          onCancel={oncancle}
          // showCancel={true}
          timeout={2000}
          showCloseButton={false}
          showConfirm={false}
          // focusCancelBtn={true}
          // confirmBtnBsStyle="danger"
          // confirmBtnText="Yes"
          // cancelBtnText="No"
        />
      )}

      {hideAlert && (
        <SweetAlert
          warning
          title="Cancel the appointment or booking?"
          onConfirm={handleBack}
          onCancel={onCancel}
          showCancel={true}
          // focusCancelBtn={true}
          // confirmBtnBsStyle="danger"
          confirmBtnText="Yes"
          cancelBtnText="No"
        />
      )}
    </div>
  );
};

export default Footer;

import React, { useEffect } from "react";
import { Modal, Form, Input, Rate, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import "antd/dist/antd.css";
import { UPDATEDPOST } from "../../services/common.api";
import { ActionTypes } from "../../redux/constants/action-types";
import { isMobile, isTablet, isBrowser } from 'react-device-detect';
import moment from 'moment'

const Rating = ({history}) => {
  // const [rating, setRating] = useState(0)
  const { isCallEndModal, setCallID } = useSelector((state) => state?.meeting);
  // const { user: { id } } = useSelector((state) => state?.userReducer ? state?.userReducer : null);
  const { user } = useSelector((state) => state?.userReducer);
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const userAgent = navigator.userAgent;

  const logEvents = async (data) => {
    console.log(data)
    try {
      let result = await UPDATEDPOST(`log`, data)
    } catch(e) {
      console.log(e, "ERRORRR")
    }
  }

  const postRating = async (values) => {

    

    if(user){
      UPDATEDPOST("userratings", {
        ...values,
        callid: setCallID,
        isPatient: 1,
        userId: user?.id,
        createdate: moment(),
      })
        .then(() => {

          let rawLogs = {
            "userIdentity": {
              "loggedinuser": `${user?.Email}`,
              "role":`${user?.role}`,
              "createdate":new Date().toISOString()
            },
            "action": "INSERT",
            "source": "User Ratings Component",
            "sourceIPAddress": "3.99.184.26",
            "userAgent": userAgent,
            "ipAddress": localStorage.getItem('ipAddress'),
            "apiendpoint": "userratings",
            "requestParameters": {
              ...values,
              callid: setCallID,
              isPatient: 1,
              userId: user?.user?.id,
            },
            // "responsestatus": response?.statusCode
          }
      
          let logBody = {
            // message: `User - ${user?.sub} - Add Rating - User Ratings Component`,
            message: rawLogs,
            logGroupName: "ONRx-Patient-Portal",
            logStreamName: `User - ${user?.sub} - User Ratings Component`,
          }
      
          logEvents(logBody);

          form.resetFields();
          history.push("/");
        })
        .catch(() => {
          notification.error({
            message: "Somthing Went Wrong",
          });
        });
      dispatch({
        type: ActionTypes.CALL_END,
        payload: false,
      });
    }
  };

  const clearTheRatting = () => {
    history.push("/");
    form.resetFields();
    dispatch({
      type: ActionTypes.CALL_END,
      payload: false,
    });
  }

  return (
    <div>
      <Modal
        visible={isCallEndModal}
        footer={null}
        onOk={postRating}
        onCancel={clearTheRatting}
      >
        <Form layout="vertical" form={form} onFinish={postRating}>
          <h4 className="mt-3 text-center">
            How was your virtual care visit experience ?
          </h4>
          <div className="row">
            <div className="col-12 text-center">
              <Form.Item name="rating">
                <Rate style={{ fontSize: "xxx-large" }} />
              </Form.Item>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Form.Item name="comments">
                <TextArea
                  className="form-control"
                  rows={4}
                  placeholder="Feedback"
                />
              </Form.Item>
            </div>
          </div>
          <div className="row ml-1 mr-1">
            <>
              <div className="pt-4 pr-3">
                <Form.Item>
                  <button type="submit" className="btn btn-primary">
                    submit
                  </button>
                </Form.Item>
              </div>
              <div className="pt-4 pr-3">
                <Form.Item>
                  <button type="button" className="btn btn-light" onClick={clearTheRatting}>
                    Cancel
                  </button>
                </Form.Item>
              </div>
            </>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default Rating;